var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavigationBar',{attrs:{"titel":"Dienstbericht erstellen","showBackBtn":""}}),(_vm.helpers.loading)?_c('Loading',{attrs:{"overlay":""}}):(!_vm.dutyTypes.length > 0)?_c('v-container',[_c('v-alert',{staticClass:"mt-6",attrs:{"type":"error","border":"left","colored-border":"","elevation":"2"}},[_c('div',{staticClass:"title"},[_vm._v("Keine Dienstarten verfügbar")]),_c('div',{staticClass:"mt-2"},[_vm._v(" Es sind keine Dienstarten für die Berichtserstellung verfügbar. Bitte deinen Administrator, Dienstarten anzulegen und zu konfigurieren. ")])])],1):[_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[[_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-step',{attrs:{"complete":_vm.step > 1,"step":"1"}},[_vm._v(" Dienstart auswählen ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[(_vm.dutyTypes.length)?_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.helpers.reportCreationDialog.dutyTypeId),callback:function ($$v) {_vm.$set(_vm.helpers.reportCreationDialog, "dutyTypeId", $$v)},expression:"helpers.reportCreationDialog.dutyTypeId"}},[_c('v-row',{staticClass:"match-height"},_vm._l((_vm.dutyTypes),function(dutyType){return _c('v-col',{key:dutyType.meta.id,attrs:{"cols":"12","md":"6"}},[_c('v-item',{attrs:{"value":dutyType.meta.id},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-card',{style:(active
                                  ? `border: 3px solid #1867c0 !important`
                                  : ''),attrs:{"outlined":""},on:{"click":toggle}},[_c('v-list-item',[_c('v-list-item-icon',[_c('data-avatar',{attrs:{"icon":dutyType.icon,"color":dutyType.color}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(dutyType.title)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(dutyType.description))])],1)],1)],1)]}}],null,true)})],1)}),1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.nextStepAfterDutyTypeSelection()}}},[_vm._v(" Weiter "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1),(
                  !(
                    _vm.eventData &&
                    _vm.eventData.meta &&
                    _vm.eventData.meta.id === _vm.$route.query.eventId
                  )
                )?_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2"}},[_vm._v(" Daten aus Veranstaltung übernehmen "),_c('small',[_vm._v("Optional")])]):_vm._e(),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"mt-1",attrs:{"items":_vm.last5AndOngoingEvents,"headers":_vm.headers,"item-key":"meta.id","item-class":"selectionStyle","sort-desc":false,"disable-pagination":"","hide-default-footer":"","mobile-breakpoint":"0","fixed-header":"","height":"35vh","no-data-text":"Keine Veranstaltungen vorhanden"},scopedSlots:_vm._u([{key:`item.type.title`,fn:function({ item }){return [_c('event-type-chip',{attrs:{"eventType":item.type,"small":""}})]}},{key:`item.eventSelection`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.helpers.reportCreationDialog.eventId = item.meta.id;
                          _vm.step = 3;}}},[_vm._v(" Auswählen")])]}},{key:`item.duration.start.firebaseFormat`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.duration.start.timestamp)+" ")]}}],null,true)}),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":"","disabled":_vm.helpers.reportCreationDialog.loading},on:{"click":function($event){_vm.step = 1}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v("Zurück ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","text":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.helpers.reportCreationDialog.eventId = null;
                        _vm.step = 3;}}},[_vm._v(" Ohne Veranstaltung fortfahren "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.step > 3,"step":"3"}},[_vm._v(" Dienstmodus starten ")]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{staticClass:"match-height"},[(
                          _vm.helpers.reportCreationDialog.dutyTypeId &&
                          _vm.currentDutyType
                        )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(" Ausgewählte Dienstart: "),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('data-avatar',{attrs:{"icon":_vm.currentDutyType.icon,"color":_vm.currentDutyType.color}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.currentDutyType.title)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.currentDutyType.subtitle))])],1)],1)],1)],1):_vm._e(),(
                          _vm.helpers.reportCreationDialog.eventId && _vm.currentEvent
                        )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(" Ausgewählte Veranstaltung: "),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-medium"},[_c('span',[_vm._v(_vm._s(_vm.currentEvent.title))])]),_c('v-list-item-subtitle',[_c('p',{staticClass:"mb-0",staticStyle:{"line-height":"1.6"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-calendar-badge")]),_vm._v(_vm._s(_vm.currentEvent.type.title)+" "),_c('br'),_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-calendar-clock")]),_vm._v(_vm._s(_vm.currentEvent.duration.start.timestamp)+" bis "+_vm._s(_vm.currentEvent.duration.end.timestamp)+" ")],1)])],1)],1)],1)],1):_vm._e()],1),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","color":"primary","type":"info"}},[_c('div',{staticClass:"title"},[_vm._v("Hinweis")]),_c('div',{staticClass:"mt-2"},[_vm._v(" Gestartete Dienste können in Echtzeit von anderen Personen eingesehen und bearbeitet werden. ")])]),(
                            this.helpers.reportCreationDialog.errorMessage
                          )?_c('v-alert',{attrs:{"text":"","color":"error","type":"error"}},[_c('div',{staticClass:"title"},[_vm._v("Fehler")]),_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(this.helpers.reportCreationDialog.errorMessage)+" ")])]):_vm._e()],1)],1)],1),_c('v-card-actions',[(!_vm.helpers.reportCreationDialog.isPrefilled)?_c('v-btn',{attrs:{"depressed":"","disabled":_vm.helpers.reportCreationDialog.loading},on:{"click":function($event){return _vm.backStepBeforeCreation()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v("Zurück ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.helpers.reportCreationDialog.loading},on:{"click":function($event){return _vm.createReport()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-ray-start-arrow")]),_vm._v(" Im Dienstmodus starten ")],1)],1)],1)],1)],1)]],2)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }