<template>
  <div>
    <v-card-title v-if="!hideCardTitle">
      <span>{{ report.title }}</span>
      <v-spacer></v-spacer>
      <report-status-chip :status="report.status" small> </report-status-chip>
    </v-card-title>
    <v-card-subtitle v-if="!hideCardTitle">
      <duty-type-chip :dutyType="report.type" small> </duty-type-chip>
    </v-card-subtitle>
    <slot name="header"> </slot>
    <v-divider></v-divider>

    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="font-weight-medium">Bezeichnung</td>
            <td>
              <v-chip
                v-if="!report.title"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ report.title }}
              </span>
            </td>
          </tr>
          <tr v-if="!hideAssignedInfo">
            <td class="font-weight-medium">Berichtsverantwortlich</td>
            <td>
              <v-chip outlined small class="my-1"
                ><v-icon small left>mdi-account-circle</v-icon
                >{{ report.assignedTo.displayName }}</v-chip
              >
            </td>
          </tr>
          <tr v-if="!hideAssignedInfo && report.correctedBy.displayName">
            <td class="font-weight-medium">Korrekturverantwortlich</td>
            <td>
              <v-chip outlined small class="my-1"
                ><v-icon small left>mdi-account-circle</v-icon
                >{{ report.correctedBy.displayName }}</v-chip
              >
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Startzeitpunkt</td>
            <td>
              <v-chip
                v-if="!report.duration.start.timestamp"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timestampConverter(report.duration.start.timestamp) }}
                Uhr
              </span>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Endzeitpunkt</td>
            <td>
              <v-chip
                v-if="!report.duration.end.timestamp"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timestampConverter(report.duration.end.timestamp) }}
                Uhr
              </span>
              <br
                v-if="
                  !report.duration.end.timestamp &&
                  report.duration.end.estimatedTimestamp
                "
              />
              <small
                v-if="
                  !report.duration.end.timestamp &&
                  report.duration.end.estimatedTimestamp
                "
                class="grey--text mt-n0"
                >Erwartet:
                {{ timestampConverter(report.duration.end.estimatedTimestamp) }}
                Uhr
              </small>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Ort</td>
            <td>
              <v-chip
                v-if="!report.location.name"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ report.location.name }}
              </span>
            </td>
          </tr>

          <tr>
            <td class="font-weight-medium">Primäre Abrechnungsart</td>
            <td>
              <v-chip
                v-if="!report.payrollType.id"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ report.payrollType.title }}
              </span>
            </td>
          </tr>
          <tr v-if="report.incident.code || report.incident.number">
            <td class="font-weight-medium">Anlass</td>
            <td>
              <span v-if="report.incident.code">{{
                report.incident.code
              }}</span>
              <span v-if="report.incident.code && report.incident.number"
                >, </span
              ><span v-if="report.incident.number"
                >E.-Nr.:{{ report.incident.number }}</span
              >
            </td>
          </tr>
          <tr v-if="report.safetyPrecautionsDocumentation">
            <td class="font-weight-medium">UVV-Dokumentation</td>
            <td>
              {{ report.safetyPrecautionsDocumentation }}
            </td>
          </tr>
          <tr v-if="report.trainingSubject">
            <td class="font-weight-medium">Ausbildungsthema</td>
            <td>
              {{ report.trainingSubject }}
            </td>
          </tr>
          <tr v-if="report.notes">
            <td class="font-weight-medium">Notizen</td>
            <td>
              {{ report.notes }}
            </td>
          </tr>
          <tr v-if="report.eventId">
            <td class="font-weight-medium">Verknüpfte Veranstaltung</td>
            <td>
              <v-btn
                small
                depressed
                class="my-1"
                :to="{
                  name: 'scheduling-event-details-tab-general',
                  params: { itemId: report.eventId },
                }"
                ><v-icon small class="mr-1">mdi-link-variant</v-icon
                >Öffnen</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-text-box</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>
        <v-chip v-if="!report.title" label small color="error"
          ><v-icon small left>mdi-alert-octagon</v-icon>Kein Titel
          festgelegt</v-chip
        >
        <span v-else class="mr-4">
          {{ report.title }}
        </span>
      </v-list-item-subtitle>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-clock-start</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>
        <v-chip
          v-if="!report.duration.start.timestamp"
          label
          small
          color="error"
          ><v-icon small left>mdi-alert-octagon</v-icon>Kein Startzeitpunkt
          festgelegt</v-chip
        >
        <span v-else class="mr-4">
          {{ timestampConverter(report.duration.start.timestamp) }}
          Uhr
        </span>
      </v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-clock-end</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>
        <v-chip v-if="!report.duration.end.timestamp" label small color="error"
          ><v-icon small left>mdi-alert-octagon</v-icon>Kein Endzeitpunkt
          festgelegt</v-chip
        >
        <span v-else class="mr-4">
          {{ timestampConverter(report.duration.end.timestamp) }} Uhr
        </span>
      </v-list-item-subtitle>
    </v-list-item>
    <v-list-item
      v-if="
        !report.duration.end.timestamp && report.duration.end.estimatedTimestamp
      "
      class="mt-n2"
    >
      <v-list-item-icon></v-list-item-icon>
      <v-list-item-subtitle>
        Erwartet:
        {{ timestampConverter(report.duration.end.estimatedTimestamp) }} Uhr
      </v-list-item-subtitle>
    </v-list-item>

    <v-list-item v-if="report.assignedTo">
      <v-list-item-icon>
        <v-icon>mdi-account-hard-hat</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle style="white-space: normal"
        >Berichtsverantwortlich:
        <v-chip label small class="ml-2 my-2"
          ><v-icon small left>mdi-account-circle</v-icon
          >{{ report.assignedTo.displayName }}</v-chip
        >
      </v-list-item-subtitle>
    </v-list-item>

    <v-list-item v-if="report.eventId">
      <v-list-item-icon>
        <v-icon>mdi-list-status</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle style="white-space: normal">
        <v-btn
          small
          depressed
          :to="{
            name: 'scheduling-event-details-tab-general',
            params: { itemId: report.eventId },
          }"
          ><v-icon small class="mr-1">mdi-link-variant</v-icon>Verknüpfte
          Veranstaltung öffnen</v-btn
        >
      </v-list-item-subtitle>
    </v-list-item> -->
  </div>
</template>

<script>
import DutyTypeChip from "@/components/duty-management/DutyTypeChip.vue";
import ReportStatusChip from "@/components/duty-management/ReportStatusChip.vue";
export default {
  name: "report-details-card-content",
  props: {
    report: {
      type: Object,
    },
    hideCardTitle: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideAssignedInfo: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: { DutyTypeChip, ReportStatusChip },
  methods: {
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
  computed: {
    attendeeItems() {
      return [];
    },
  },
};
</script>
