<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      elevation="2"
      type="article, divider, list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>
    <v-card v-else>
      <report-details-card-content
        :report="report"
      ></report-details-card-content>
    </v-card>
  </div>
</template>

<script>
import ReportDetailsCardContent from "@/components/duty-management/ReportDetailsCardContent.vue";
export default {
  name: "report-details-card",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    report: {
      type: Object,
    },
  },
  components: { ReportDetailsCardContent },
  methods: {},
  computed: {},
};
</script>
