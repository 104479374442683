<template>
  <div>
    <NavigationBar titel="Dienstbericht erstellen" showBackBtn></NavigationBar>
    <Loading v-if="helpers.loading" overlay></Loading>
    <v-container v-else-if="!dutyTypes.length > 0">
      <v-alert
        type="error"
        border="left"
        colored-border
        elevation="2"
        class="mt-6"
      >
        <div class="title">Keine Dienstarten verfügbar</div>
        <div class="mt-2">
          Es sind keine Dienstarten für die Berichtserstellung verfügbar. Bitte
          deinen Administrator, Dienstarten anzulegen und zu konfigurieren.
        </div>
      </v-alert>
    </v-container>
    <template v-else>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <template>
              <v-stepper v-model="step" vertical>
                <v-stepper-step :complete="step > 1" step="1">
                  Dienstart auswählen
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-card outlined>
                    <v-card-text>
                      <v-item-group
                        v-if="dutyTypes.length"
                        v-model="helpers.reportCreationDialog.dutyTypeId"
                        mandatory
                      >
                        <v-row class="match-height">
                          <v-col
                            v-for="dutyType in dutyTypes"
                            :key="dutyType.meta.id"
                            cols="12"
                            md="6"
                          >
                            <v-item
                              v-slot="{ active, toggle }"
                              :value="dutyType.meta.id"
                            >
                              <v-card
                                outlined
                                @click="toggle"
                                :style="
                                  active
                                    ? `border: 3px solid #1867c0 !important`
                                    : ''
                                "
                              >
                                <v-list-item>
                                  <v-list-item-icon>
                                    <data-avatar
                                      :icon="dutyType.icon"
                                      :color="dutyType.color"
                                    ></data-avatar>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="font-weight-medium"
                                    >
                                      {{ dutyType.title }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      {{
                                        dutyType.description
                                      }}</v-list-item-subtitle
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-item-group>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        depressed
                        color="primary"
                        @click="nextStepAfterDutyTypeSelection()"
                      >
                        Weiter
                        <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step
                  v-if="
                    !(
                      eventData &&
                      eventData.meta &&
                      eventData.meta.id === $route.query.eventId
                    )
                  "
                  :complete="step > 2"
                  step="2"
                >
                  Daten aus Veranstaltung übernehmen

                  <small>Optional</small>
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-card outlined>
                    <v-data-table
                      :items="last5AndOngoingEvents"
                      :headers="headers"
                      item-key="meta.id"
                      item-class="selectionStyle"
                      :sort-desc="false"
                      disable-pagination
                      hide-default-footer
                      mobile-breakpoint="0"
                      fixed-header
                      height="35vh"
                      class="mt-1"
                      no-data-text="Keine Veranstaltungen vorhanden"
                    >
                      <!-- <template
                        v-slot:[`group.header`]="{ items, isOpen, toggle }"
                      >
                        <th :colspan="headers.length">
                          <v-icon @click="toggle" class="mr-2"
                            >{{
                              isOpen ? "mdi-chevron-up" : "mdi-chevron-down"
                            }}
                          </v-icon>
                          {{ stripPrefix(items[0].groupBy) }}
                          ({{ getCountOfEventsInMonth(items[0].groupBy) }})
                        </th>
                      </template> -->
                      <template v-slot:[`item.type.title`]="{ item }">
                        <event-type-chip :eventType="item.type" small>
                        </event-type-chip>
                      </template>
                      <template v-slot:[`item.eventSelection`]="{ item }">
                        <v-btn
                          small
                          depressed
                          color="primary"
                          @click="
                            helpers.reportCreationDialog.eventId = item.meta.id;
                            step = 3;
                          "
                        >
                          Auswählen</v-btn
                        >
                      </template>
                      <template
                        v-slot:[`item.duration.start.firebaseFormat`]="{ item }"
                      >
                        {{ item.duration.start.timestamp }}
                      </template>
                    </v-data-table>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        depressed
                        :disabled="helpers.reportCreationDialog.loading"
                        @click="step = 1"
                      >
                        <v-icon left>mdi-chevron-left</v-icon>Zurück
                      </v-btn>
                      <v-spacer></v-spacer>

                      <v-btn
                        depressed
                        text
                        outlined
                        color="primary"
                        @click="
                          helpers.reportCreationDialog.eventId = null;
                          step = 3;
                        "
                      >
                        Ohne Veranstaltung fortfahren
                        <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="step > 3" step="3">
                  Dienstmodus starten
                </v-stepper-step>

                <v-stepper-content step="3">
                  <v-card flat>
                    <v-card-text>
                      <v-row class="match-height">
                        <v-col
                          v-if="
                            helpers.reportCreationDialog.dutyTypeId &&
                            currentDutyType
                          "
                          cols="12"
                          md="6"
                        >
                          Ausgewählte Dienstart:
                          <v-card outlined class="mt-2">
                            <v-list-item>
                              <v-list-item-icon>
                                <data-avatar
                                  :icon="currentDutyType.icon"
                                  :color="currentDutyType.color"
                                ></data-avatar>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                  {{ currentDutyType.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{
                                    currentDutyType.subtitle
                                  }}</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            helpers.reportCreationDialog.eventId && currentEvent
                          "
                          cols="12"
                          md="6"
                        >
                          Ausgewählte Veranstaltung:
                          <v-card outlined class="mt-2">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="subtitle-1 font-weight-medium"
                                >
                                  <span>{{ currentEvent.title }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <p style="line-height: 1.6" class="mb-0">
                                    <v-icon small left
                                      >mdi-calendar-badge</v-icon
                                    >{{ currentEvent.type.title }}
                                    <br />
                                    <v-icon small left
                                      >mdi-calendar-clock</v-icon
                                    >{{ currentEvent.duration.start.timestamp }}
                                    bis
                                    {{ currentEvent.duration.end.timestamp }}
                                  </p>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row class="mt-8">
                        <v-col cols="12">
                          <v-alert text color="primary" type="info">
                            <div class="title">Hinweis</div>
                            <div class="mt-2">
                              Gestartete Dienste können in Echtzeit von anderen
                              Personen eingesehen und bearbeitet werden.
                            </div>
                          </v-alert>
                          <v-alert
                            v-if="
                              this.helpers.reportCreationDialog.errorMessage
                            "
                            text
                            color="error"
                            type="error"
                          >
                            <div class="title">Fehler</div>
                            <div class="mt-2">
                              {{
                                this.helpers.reportCreationDialog.errorMessage
                              }}
                            </div>
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        v-if="!helpers.reportCreationDialog.isPrefilled"
                        depressed
                        @click="backStepBeforeCreation()"
                        :disabled="helpers.reportCreationDialog.loading"
                      >
                        <v-icon left>mdi-chevron-left</v-icon>Zurück
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        color="primary"
                        :loading="helpers.reportCreationDialog.loading"
                        @click="createReport()"
                      >
                        <v-icon left>mdi-ray-start-arrow</v-icon>
                        Im Dienstmodus starten
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
              </v-stepper>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { db, functions, Timestamp } from "@/firebase";
import { DUTY_MANAGEMENT } from "@/store/modules.js";
import { GET_REPORT_TYPES } from "@/store/action-types.js";
import Loading from "@/components/_system/helpers/Loading.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
import EventTypeChip from "@/components/scheduling/EventTypeChip.vue";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "duty-management-reports-new",
  components: {
    Loading,
    DataAvatar,
    EventTypeChip,
    NavigationBar,
    // Subheader,
  },
  data() {
    return {
      step: 1,
      stepperState: 1,
      loading: true,
      headers: [
        { text: "Veranstaltungstitel", value: "title" },
        { text: "Veranstaltungsart", value: "type.title" },
        { text: "Startzeitpunkt", value: "duration.start.firebaseFormat" },
        {
          text: "Endzeitpunkt",
          value: "duration.end.timestamp",
          sortable: false,
        },
        // { text: "Status", value: "status" },
        { text: "", value: "eventSelection", sortable: false },
      ],
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      report: {
        title: "",
        connectedEventId: "",
        duration: {
          start: { timestamp: null, weekday: null },
          end: { timestamp: null, isOpen: false, weekday: null },
          inHours: 0,
          inMinutes: 0,
        },
        dutyType: {
          id: "",
          title: "",
        },
        incident: {
          code: "",
          number: "",
          date: null,
        },
        payrollType: {
          id: "",
          title: "",
          formula: "",
        },
        status: "in progress", // "in progress", "confirmed", "locked"
        isLocked: false,
        units: {
          ids: [],
          data: [],
        },
        attendances: {
          data: [],
          personIds: [],
          presentPersonIds: [],
          absentPersonIds: [],
          excusedPersonIds: [],
          unexcusedPersonIds: [],
        },
        logs: [],
      },
      eventData: null,
      helpers: {
        loading: true,
        missingDutyTypeError: false, // TODO: change behaviour to show a dialog
        formIsValid: false,
        reportCreationDialog: {
          model: false,
          loading: false,
          errorMessage: "",
          dutyTypeId: "",
          eventId: "",
          isPrefilled: false,
        },
      },
      last5AndOngoingEvents: [],
    };
  },
  computed: {
    dutyTypes() {
      // sort these by sortKey ascending
      const dutyType = this.$store.state.dutyManagement.dutyTypes;
      return dutyType.sort((a, b) => a.sortKey - b.sortKey);
    },
    currentDutyType() {
      return this.dutyTypes.find(
        (dutyType) =>
          dutyType.meta.id === this.helpers.reportCreationDialog.dutyTypeId
      );
    },
    currentEvent() {
      if (
        this.eventData &&
        this.eventData.meta &&
        this.eventData.meta.id === this.helpers.reportCreationDialog.eventId
      ) {
        return this.eventData;
      } else {
        return this.last5AndOngoingEvents.find(
          (event) => event.meta.id === this.helpers.reportCreationDialog.eventId
        );
      }
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    async initialize() {
      this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORT_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });

      this.getEventsForSelection();

      const { dutyTypeId, eventId } = this.$route.query;
      if (dutyTypeId) {
        this.helpers.reportCreationDialog.dutyTypeId = dutyTypeId;
      }
      if (eventId) {
        this.helpers.reportCreationDialog.eventId = eventId;
        // Warten auf das Ergebnis von getEvent
        this.eventData = await this.getEvent(eventId);
        console.log(this.eventData);
      }

      if (dutyTypeId && eventId) {
        this.helpers.reportCreationDialog.isPrefilled = true;
        this.step = 3;
      } else if (!dutyTypeId && eventId) {
        this.step = 1;
      } else if (dutyTypeId) {
        this.step = 2;
      }
      this.helpers.loading = false;
    },
    backStepBeforeCreation() {
      if (
        this.eventData &&
        this.eventData.meta &&
        this.eventData.meta.id === this.$route.query.eventId
      ) {
        this.step = 1;
      } else {
        this.step = 2;
      }
    },
    nextStepAfterDutyTypeSelection() {
      if (
        this.eventData &&
        this.eventData.meta &&
        this.eventData.meta.id === this.$route.query.eventId
      ) {
        this.step = 3;
      } else {
        this.step = 2;
      }
    },
    getEventsForSelection() {
      const events = [];
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .where(
          "duration.start.timestamp",
          "<=",
          Timestamp.fromDate(
            new Date(new Date().getTime() + 4 * 60 * 60 * 1000)
          )
        )
        .orderBy("duration.start.timestamp", "desc")
        .limit(10)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            return events.push({
              ...doc.data(),
              // overwrite
              duration: {
                start: {
                  firebaseFormat: doc.data().duration.start.timestamp,
                  dateFormat: doc.data().duration.start.timestamp.toDate(),
                  timestamp: doc
                    .data()
                    .duration.start.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.start.isOpen,
                },
                end: {
                  firebaseFormat: doc.data().duration.end.timestamp,
                  timestamp: doc
                    .data()
                    .duration.end.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.end.isOpen,
                },
              },
            });
          });
          this.last5AndOngoingEvents = events;
        })
        .catch((error) => console.log(error));
    },
    btnAction() {
      if (this.stepperState === 1) {
        this.stepperState = 2;
      } else {
        alert("done");
      }
    },
    async getEvent(eventId) {
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(eventId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            return {
              ...doc.data(),
              // overwrite
              duration: {
                start: {
                  firebaseFormat: doc.data().duration.start.timestamp,
                  dateFormat: doc.data().duration.start.timestamp.toDate(),
                  timestamp: doc
                    .data()
                    .duration.start.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.start.isOpen,
                },
                end: {
                  firebaseFormat: doc.data().duration.end.timestamp,
                  timestamp: doc
                    .data()
                    .duration.end.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.end.isOpen,
                },
              },
            };
          }
          return null;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return null;
        });
    },
    createReport() {
      try {
        if (this.helpers.reportCreationDialog.dutyTypeId) {
          this.helpers.reportCreationDialog.loading = true;
          this.helpers.reportCreationDialog.errorMessage = "";

          const callFunction = functions.httpsCallable(
            "dutyManagement-createReport"
          );
          return callFunction({
            organizationId: this.$route.params.organizationId,
            dutyTypeId: this.helpers.reportCreationDialog.dutyTypeId,
            eventId: this.helpers.reportCreationDialog.eventId,
          })
            .then((response) => {
              const reportId = response.data;
              this.$router.push({
                name: "duty-management-reports-live",
                params: {
                  organizationId: this.$route.params.organizationId,
                  itemId: reportId,
                },
              });
            })
            .catch((error) => {
              this.helpers.reportCreationDialog.errorMessage = error.message;
              this.helpers.reportCreationDialog.loading = false;
              console.error("Error creating report: ", error);
            });
        } else {
          alert("Prüfen Deine Eingabe und versuchen es erneut.");
        }
      } catch (error) {
        this.helpers.reportCreationDialog.errorMessage =
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.";
        this.helpers.reportCreationDialog.loading = false;
        console.error("Unexpected error: ", error);
      }
    },
  },
};
</script>

<style></style>
