<template>
  <div>
    <div class="d-flex flex-column">
      <v-menu
        v-model="helpers.positionSelector.swap.model"
        offset-y
        :max-width="280"
        :min-width="280"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon disabled>
            <v-icon>mdi-swap-horizontal-bold</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title> TAUSCH </v-card-title>
        </v-card>
      </v-menu>
      <v-menu
        v-model="helpers.positionSelector.remove.model"
        offset-y
        :max-width="290"
        :min-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <!-- @click="
                                            deletePositionAssignment(
                                              unit.id,
                                              position.id,
                                              user.uid
                                            )
                                          " -->
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <v-card :loading="helpers.positionSelector.remove.loading">
          <v-card-title>
            <v-btn
              block
              depressed
              @click="deletePositionAssignment()"
              class="mb-1"
            >
              <v-icon left>mdi-account-minus</v-icon>
              Funktionszuweisung entfernen
            </v-btn>
            <v-btn
              color="red"
              disabled
              block
              depressed
              class="mt-1"
              @click="setPersonAsAbsent()"
            >
              <v-icon left>mdi-run-fast</v-icon>Person als abwesend melden
            </v-btn>
          </v-card-title>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { db, auth, FieldValue } from "@/firebase";
export default {
  name: "report-unit-person-actions",
  props: {
    attendanceEntry: {},
  },
  components: {},
  data() {
    return {
      helpers: {
        positionSelector: {
          swap: {
            model: false,
            loading: false,
          },
          remove: {
            model: false,
            loading: false,
          },
        },
      },
    };
  },
  //   model: {
  //     prop: "conditionItem",
  //     event: "conditionItemChange",
  //   },
  created() {},
  computed: {
    // conditionItemLocal: {
    //   get: function () {
    //     return this.conditionItem;
    //   },
    //   set: function (value) {
    //     this.$emit("conditionItemChange", value);
    //   },
    // },
  },
  methods: {
    deletePositionAssignment() {
      this.helpers.positionSelector.remove.model = false;
      const emptyPosition = {
        unit: { title: null, id: null },
        position: { title: null, id: null },
        comboId: "",
      };
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId)
        .collection("dutyAttendances")
        .doc(this.attendanceEntry.meta.id)
        .update(
          {
            assignedPosition: emptyPosition,
            "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
            "meta.lastUpdatedBy": auth.currentUser.uid,
          },
          { merge: true }
        )
        .then(() => {
          this.helpers.positionSelector.remove.model = false;
          this.helpers.positionSelector.remove.loading = false;
        })
        .catch((error) => {
          console.log(error);
          alert("Fehler: Einsatzmittel konnte nicht hinzugefügt werden.");
          this.helpers.positionSelector.remove.loading = false;
        });
    },

    setPersonAsAbsent() {
      this.helpers.positionSelector.model = false;
      console.log("setPersonAsAbsent");
    },
  },
};
</script>
