import { render, staticRenderFns } from "./ReportDetailsCard.vue?vue&type=template&id=34a95f40"
import script from "./ReportDetailsCard.vue?vue&type=script&lang=js"
export * from "./ReportDetailsCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports