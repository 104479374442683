<template>
  <div>
    <section>
      <div>
        <!-- <v-container> -->
        <v-row>
          <v-col cols="12" sm="8" md="8" lg="8">
            <v-card>
              <v-card-title>
                <v-btn
                  depressed
                  outlined
                  text
                  color="error"
                  :disabled="!reportLocal.units.data.length"
                  @click="deleteAllUnits()"
                  ><v-icon left>mdi-delete</v-icon>Alle entfernen</v-btn
                >
                <v-spacer></v-spacer>
                <v-menu
                  v-model="helpers.unitSelector.model"
                  :close-on-click="false"
                  :close-on-content-click="false"
                  :nudge-width="150"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success" depressed v-bind="attrs" v-on="on">
                      <v-icon left>mdi-plus</v-icon>Einsatzmittel hinzufügen
                    </v-btn>
                  </template>
                  <v-card>
                    <v-form
                      @submit.prevent="addUnit"
                      ref="unitSelector"
                      v-model="helpers.unitSelector.isValid"
                    >
                      <v-list>
                        <v-list-item>
                          <v-select
                            v-model="helpers.unitSelector.nextUnit"
                            :items="addableUnits"
                            item-text="title"
                            item-value="id"
                            class="pt-1"
                            :rules="[rules.required]"
                            return-object
                            label="Einsatzmittel auswählen"
                            no-data-text="Keine Einsatzmittel vorhanden"
                            outlined
                          >
                          </v-select>
                        </v-list-item>
                        <v-list-item>
                          <v-select
                            v-model="
                              helpers.unitSelector.nextUnitCrewCombination
                            "
                            :items="nextUnitCrewCombinations"
                            item-text="title"
                            :rules="[rules.required]"
                            return-object
                            label="Besetzungsart auswählen"
                            no-data-text="Keine Besetzungsart vorhanden"
                            outlined
                          >
                          </v-select>
                        </v-list-item>
                      </v-list>

                      <v-card-actions class="mt-n4">
                        <v-btn text @click="closeUnitSelector()">
                          Abbrechen
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="success"
                          text
                          :disabled="!helpers.unitSelector.isValid"
                          @click="addUnit()"
                        >
                          <v-icon left>mdi-plus</v-icon>Hinzufügen
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-menu>
              </v-card-title>
            </v-card>

            <v-data-iterator
              :items="unitsDisplay"
              hide-default-footer
              disable-pagination
              no-data-text="Keine Einsatzmittel hinzugefügt."
              class="mt-6"
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col v-for="unit in props.items" :key="unit.id" cols="12">
                    <v-card>
                      <v-card-title
                        ><img
                          :src="unit.photoUrl.size400x400"
                          contain
                          height="80px"
                          class="mb-2 mr-4"
                        />

                        {{ unit.title }}<v-spacer></v-spacer
                        ><v-btn depressed @click="resetUnit(unit.id)"
                          ><v-icon left>mdi-reload</v-icon> Zurücksetzen</v-btn
                        ><v-btn
                          depressed
                          text
                          outlined
                          color="error"
                          @click="deleteUnit(unit.id)"
                          class="ml-2"
                          ><v-icon left>mdi-delete</v-icon>Löschen</v-btn
                        ></v-card-title
                      >
                      <v-card-subtitle
                        >{{ unit.selectedCrewCombination.title }} ({{
                          unit.selectedCrewCombination.strength
                        }})</v-card-subtitle
                      >
                      <v-card-text>
                        <v-row class="match-height">
                          <v-col cols="12"
                            ><v-row class="match-height">
                              <v-col
                                v-for="(position, index) in unit
                                  .selectedCrewCombination.positions"
                                :key="index"
                                cols="12"
                                sm="12"
                                md="6"
                              >
                                <v-card
                                  outlined
                                  :color="
                                    $vuetify.theme.dark
                                      ? 'grey darken-3'
                                      : 'grey lighten-4'
                                  "
                                >
                                  <v-card-title
                                    class="subtitle-1 font-weight-medium py-2"
                                    >{{ position.position.title }}
                                  </v-card-title>
                                  <v-card-subtitle class="pb-2">
                                    {{ position.position.shortTitle }}
                                    <span
                                      v-if="position.isOptional"
                                      class="ml-1"
                                    >
                                      (*Optional)
                                    </span>
                                  </v-card-subtitle>
                                  <!-- getAttendeesAssignedToPosition(
                                      unit.id,
                                      position.position.id
                                    )" -->
                                  <v-card-title
                                    v-for="attendanceEntry in position.assignedPersons"
                                    :key="attendanceEntry.attendanceId"
                                    class="pt-0 d-flex"
                                  >
                                    <v-card outlined class="mr-2 flex-grow-1">
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{
                                              attendanceEntry.user.displayName
                                            }}
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                            {{
                                              getAttendanceEntryTimesText(
                                                attendanceEntry
                                              )
                                            }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-btn
                                            icon
                                            @click="
                                              togglePersonDetails(
                                                attendanceEntry.user.uid
                                              )
                                            "
                                            disabled
                                          >
                                            <v-icon
                                              >mdi-information-outline</v-icon
                                            >
                                          </v-btn>
                                        </v-list-item-action>
                                      </v-list-item>
                                    </v-card>
                                    <report-unit-person-first-actions
                                      :attendanceEntry="attendanceEntry"
                                    ></report-unit-person-first-actions>
                                    <report-unit-person-second-actions
                                      :attendanceEntry="attendanceEntry"
                                    ></report-unit-person-second-actions>
                                  </v-card-title>
                                  <v-card-title class="pt-0">
                                    <v-card
                                      outlined
                                      class="mr-2 flex-grow-1"
                                      style="border-style: dashed"
                                    >
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-btn
                                            text
                                            @click="
                                              togglePersonChooserDialog(
                                                unit,
                                                position
                                              )
                                            "
                                            ><v-icon left
                                              >mdi-account-plus</v-icon
                                            >Person einteilen</v-btn
                                          >
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-card>
                                  </v-card-title>
                                </v-card>
                              </v-col>
                            </v-row></v-col
                          >
                        </v-row>
                      </v-card-text>
                    </v-card></v-col
                  >
                </v-row>
              </template>
            </v-data-iterator>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4">
            <!-- <v-card>
              <apexchart
                type="bar"
                height="240"
                :series="options.series"
                :options="options"
              ></apexchart>
            </v-card> -->
            <!-- class="left-card overflow-y-auto"
            min-height="140px"
            max-height="840px" -->
            <v-card>
              <v-card-title class="subtitle-1 font-weight-medium"
                >Anwesende Personen
                <v-spacer></v-spacer>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed small v-bind="attrs" v-on="on">
                      <span>
                        <strong>{{ personListSelectorText }}</strong>
                      </span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list nav>
                    <v-list-item-group
                      v-model="helpers.personListSelector"
                      mandatory
                    >
                      <v-list-item link dense value="skills" disabled>
                        <v-list-item-icon>
                          <v-icon disabled dense>mdi-label</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Qualifikationen</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        dense
                        value="performanceTarget"
                        disabled
                      >
                        <v-list-item-icon>
                          <v-icon dense disabled>mdi-bullseye-arrow</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Zielvorgaben</v-list-item-title>
                      </v-list-item>
                      <v-list-item link dense value="group.title">
                        <v-list-item-icon>
                          <v-icon dense>mdi-folder-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Benutzergruppe</v-list-item-title>
                      </v-list-item>
                      <v-list-item link dense value="user.division.title">
                        <v-list-item-icon>
                          <v-icon dense>mdi-home-group</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Einheit/Abteilung</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu></v-card-title
              >
              <v-divider></v-divider>

              <v-data-table
                :items="assignablePersons"
                hide-default-footer
                disable-pagination
                item-key="meta.id"
                dense
                fixed-header
                height="83vh"
                :group-by="helpers.personListSelector"
                no-data-text="Keine Personen für Einteilung vorhanden."
              >
                <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                  <th :colspan="1">
                    <v-icon @click="toggle" class="mr-2"
                      >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                    {{ getGroupHeaderText(items[0]) }}
                  </th>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <v-card outlined class="my-2 mx-4">
                      <v-card-title
                        class="py-2 subtitle-1 font-weight-medium"
                        style="word-break: break-word"
                      >
                        {{ item.user.displayName }}
                      </v-card-title>
                      <v-divider></v-divider>
                      <!-- <v-card-subtitle>
                        <performance-target-status-chip
                          status="targetNotMet"
                          small
                        >
                        </performance-target-status-chip>
                      </v-card-subtitle> -->
                      <v-card-text class="pb-1">
                        <div class="text-caption font-weight-medium mb-1">
                          Qualifikationen
                        </div>
                        <v-row class="match-height" dense>
                          <v-col>
                            <div
                              v-if="!item.user.relevantSkills.data.length"
                              class="mb-1"
                            >
                              Keine Qualifikationen zugewiesen
                            </div>

                            <data-chip
                              v-for="(skill, index) in item.user.relevantSkills
                                .data"
                              :key="index"
                              :color="skill.group.color"
                              :iconLeft="skill.group.icon"
                              :text="skill.shortTitle"
                              class="mr-2 mb-2"
                              small
                            >
                            </data-chip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <!-- </v-container> -->
      </div>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.personChooserDialog.dialogModel"
        @close="togglePersonChooserDialog(null, null)"
        title="Person auswählen"
        width="m"
        :errorMessage="helpers.personChooserDialog.errorMessage"
        :loading="helpers.personChooserDialog.loading"
        btnText="Person auswählen"
        :btnAction="addPersonToPosition"
        :btnDisabled="!helpers.personChooserDialog.value.length"
        show
        fullscreenOnMobile
      >
        <template v-slot:titleChip
          ><v-chip label outlined class="ml-4 font-weight-medium"
            ><v-icon small left>mdi-fire-truck</v-icon
            >{{ helpers.personChooserDialog.unit.title }}
          </v-chip>
          <v-chip label outlined class="ml-2 font-weight-medium"
            ><v-icon small left>mdi-tag</v-icon>
            {{
              helpers.personChooserDialog.position.position.shortTitle
            }}</v-chip
          ></template
        >
        <template v-slot:content>
          <v-form v-model="helpers.personChooserDialog.formIsValid">
            <!-- @submit.prevent="startDataImport()" -->

            <v-data-iterator
              v-model="helpers.personChooserDialog.value"
              :items="assignablePersons"
              :sort-by="helpers.personChooserDialog.sortSelector"
              :sort-desc="false"
              item-key="meta.id"
              disable-pagination
              hide-default-footer
              :search="helpers.personChooserDialog.search"
              class="mt-1"
              no-data-text="Keine Personen vorhanden"
            >
              <template v-slot:default="props">
                <v-card outlined class="mt-2">
                  <v-card-subtitle>
                    <v-row>
                      <!-- <v-col>
                        <v-text-field
                          v-model="helpers.personChooserDialog.search"
                          label="Personen suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col> -->
                      <v-col class="d-flex justify-end"
                        ><v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              text
                              disabled
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left small>mdi-filter</v-icon>
                              {{ helpers.personChooserDialog.sortSelector }}
                              <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                          </template>
                          <v-list nav>
                            <v-list-item-group
                              v-model="helpers.personChooserDialog.sortSelector"
                              mandatory
                            >
                              <v-list-item link dense value="Alle anzeigen">
                                <v-list-item-icon>
                                  <v-icon dense>mdi-list-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Alle anzeigen</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                link
                                dense
                                value="Qualifikation vorhanden"
                              >
                                <v-list-item-icon>
                                  <v-icon dense>mdi-label</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Qualifikation vorhanden</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                link
                                dense
                                value="Zielvorgabe erfüllt"
                              >
                                <v-list-item-icon>
                                  <v-icon dense>mdi-bullseye-arrow</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Zielvorgabe erfüllt</v-list-item-title
                                >
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu></v-col
                      >
                    </v-row>
                  </v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row class="match-height" dense>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                      >
                        <v-card outlined>
                          <v-card-title
                            class="py-2 subtitle-1 font-weight-medium"
                            style="word-break: break-word"
                          >
                            {{ item.user.displayName }}
                            <v-spacer></v-spacer>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-list dense class="py-0">
                            <div
                              v-for="(
                                attendanceEntry, index
                              ) in getAttendanceEntriesOfUser(item.user.uid)"
                              :key="index"
                            >
                              <v-list-item
                                :style="
                                  helpers.personChooserDialog.value.includes(
                                    attendanceEntry.meta.id
                                  )
                                    ? 'border: 3px solid #1976D2 !important'
                                    : ''
                                "
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="font-weight-regular subtitle-2"
                                    ><strong>Eintrag {{ index + 1 }}:</strong>
                                    {{ attendanceEntry.duration.start.time }}
                                    bis
                                    {{
                                      attendanceEntry.duration.end.time
                                        ? attendanceEntry.duration.end.time
                                        : "Ende"
                                    }}</v-list-item-title
                                  >
                                </v-list-item-content>
                                <v-list-item-action
                                  ><v-btn
                                    v-if="
                                      !helpers.personChooserDialog.value.includes(
                                        attendanceEntry.meta.id
                                      )
                                    "
                                    depressed
                                    small
                                    @click="
                                      selectPerson(attendanceEntry.meta.id)
                                    "
                                    color="primary"
                                    >Auswählen</v-btn
                                  >
                                  <span
                                    v-else
                                    class="grey--text caption uppercase"
                                    >Ausgewählt</span
                                  ></v-list-item-action
                                ></v-list-item
                              ><v-divider></v-divider>
                            </div>
                          </v-list>

                          <!-- <v-card-subtitle
                            v-if="
                              item.user.dutyPosition.title ||
                              item.user.rank.title
                            "
                            class="pb-2"
                          >
                            <v-chip
                              outlined
                              label
                              v-if="item.user.rank.title"
                              class="mr-2"
                            >
                              <img
                                v-if="item.user.rank.badgeUrl.size125x125"
                                :src="item.user.rank.badgeUrl.size125x125"
                                contain
                                height="20px"
                                class="mr-2"
                              />
                              <span class="font-weight-medium">{{
                                item.user.rank.shortTitle
                              }}</span>
                            </v-chip>

                            <v-chip
                              outlined
                              label
                              v-if="item.user.dutyPosition.title"
                            >
                              <img
                                v-if="
                                  item.user.dutyPosition.badgeUrl.size125x125
                                "
                                :src="
                                  item.user.dutyPosition.badgeUrl.size125x125
                                "
                                contain
                                height="20px"
                                class="mr-2"
                              />
                              <span class="font-weight-medium">{{
                                item.user.dutyPosition.shortTitle
                              }}</span>
                            </v-chip>
                          </v-card-subtitle> -->
                          <!-- <v-divider></v-divider> -->
                          <!-- <v-card-subtitle >
                            <performance-target-status-chip
                              status="targetUnknown"
                              small
                            >
                            </performance-target-status-chip>
                          </v-card-subtitle> -->
                          <!-- <v-divider></v-divider> -->
                          <!-- <v-card-text>
                            <div
                              class="text-caption font-weight-medium mt-n2 mb-1"
                            >
                              Qualifikationen
                            </div>
                            <v-row class="match-height" dense> -->
                          <!-- <v-col
                                v-if="
                                  memberManagementConfig.badgeDisplay
                                    .primarySkillGroupId &&
                                  item.relevantSkills.data.length
                                "
                                cols="3"
                              >
                                <v-card
                                  outlined
                                  class="pa-2 d-flex justify-center align-center"
                                  height="80px"
                                  width="80px"
                                >
                                  <v-img contain :src="primaryBadgeUrl(item)">
                                  </v-img>
                                </v-card>
                              </v-col> -->
                          <!-- <v-col>
                                <div
                                  v-if="!item.user.relevantSkills.data.length"
                                >
                                  Keine Qualifikationen zugewiesen
                                </div>

                                <data-chip
                                  v-for="(skill, index) in item.user
                                    .relevantSkills.data"
                                  :key="index"
                                  :color="skill.group.color"
                                  :iconLeft="skill.group.icon"
                                  :text="skill.shortTitle"
                                  class="mr-2 mb-2"
                                  small
                                >
                                </data-chip>
                              </v-col> -->
                          <!-- </v-row>
                          </v-card-text> -->
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-data-iterator>
          </v-form>
        </template>
      </custom-dialog>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import { db, auth, FieldValue } from "@/firebase";
// import VueApexCharts from "vue-apexcharts";
import DataChip from "@/components/_systemwide/DataChip.vue";
// import PerformanceTargetStatusChip from "@/components/member-management/performance-targets/PerformanceTargetStatusChip.vue";
import ReportUnitPersonFirstActions from "@/components/duty-management/ReportUnitPersonFirstActions.vue";
import ReportUnitPersonSecondActions from "@/components/duty-management/ReportUnitPersonSecondActions.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";

export default {
  name: "report-live-unit-view",
  props: {
    report: {},
    attendances: {},
    units: {
      type: Array,
      default: () => [],
    },
    addableAttendees: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SupportTools,
    ReportUnitPersonFirstActions,
    ReportUnitPersonSecondActions,
    // PerformanceTargetStatusChip,
    // apexchart: VueApexCharts,
    CustomDialog,
    DataChip,
  },
  model: {
    prop: "report",
    event: "reportChange",
  },

  data() {
    return {
      options: {
        series: [
          {
            name: "Eingeteilt",
            data: [3, 4, 3, 1, 2],
          },
          {
            name: "Nicht eingeteilt",
            data: [4, 6, 5, 5, 1],
          },
        ],
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ["#66BB6A", "#EF5350"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 8,
            borderRadiusApplication: "end", // 'around', 'end'
            borderRadiusWhenStacked: "last", // 'all', 'last'
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          categories: ["X", "TL", "TF", "FZF", "ZF"],
        },
        fill: {
          opacity: 1,
        },
      },

      helpers: {
        personListSelector: "user.division.title",
        attendanceSelection: [],
        displayMode: "cards",
        unitSelector: {
          model: false,
          isValid: false,
          loading: false,
          errorMessage: "",
          nextUnit: null,
          nextUnitCrewCombination: null,
        },
        personDetailsDialog: {
          dialogModel: false,
        },
        personChooserDialog: {
          value: [],
          dialogModel: false,
          formIsValid: false,
          errorMessage: "",
          loading: false,
          unit: null,
          position: null,
          tableHeaders: [
            {
              text: "Name",
              sortable: true,
              value: "user.displayName",
            },
          ],
          search: "",
          sortSelector: "Alle anzeigen",
        },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  computed: {
    reportLocal: {
      get: function () {
        return this.report;
      },
      set: function (value) {
        this.$emit("reportChange", value);
      },
    },
    attendancesLocal: {
      get: function () {
        return this.attendances;
      },
      set: function (value) {
        this.$emit("attendancesChange", value);
      },
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        { title: "reportLocal", data: this.reportLocal },
        { title: "attendancesLocal", data: this.attendancesLocal },
        { title: "addableAttendees", data: this.addableAttendees },
      ];
    },
    personListSelectorText() {
      if (this.helpers.personListSelector === "performanceTarget")
        return "Zielvorgaben";
      if (this.helpers.personListSelector === "skills")
        return "Qualifikationen";
      if (this.helpers.personListSelector === "user.division.title") {
        return "Einheit/Abteilung";
      }
      if (this.helpers.personListSelector === "group.title")
        return "Benutzergruppe";
      return "";
    },
    addableUnits() {
      const existingUnitIds = this.reportLocal.units.ids;
      return this.units
        .filter((unit) => !existingUnitIds.includes(unit.meta.id))
        .map((unit) => {
          return {
            title: unit.title,
            id: unit.meta.id,
            organizationId: unit.organizationId,
            isExternal: unit.isExternal,
            priority: unit.priority,
          };
        });
    },
    memberManagementConfig() {
      return this.$store.state.organization.activeOrganization.config
        .memberManagement;
    },
    assignablePersons() {
      const uniqueAttendees = new Set();

      return this.attendancesLocal.filter(({ attendanceStatus, user }) => {
        if (attendanceStatus === "1" && !uniqueAttendees.has(user.uid)) {
          uniqueAttendees.add(user.uid);
          return true;
        }
        return false;
      });
    },
    nextUnitCrewCombinations() {
      const unit =
        this.helpers.unitSelector.nextUnit &&
        this.helpers.unitSelector.nextUnit.id
          ? this.units.find(
              (unit) => unit.meta.id === this.helpers.unitSelector.nextUnit.id
            )
          : null;
      return unit ? unit.crewCombinations : [];
    },
    unitsDisplay() {
      const units = this.reportLocal.units.data;
      return units
        .map((unit) => {
          return {
            ...unit,
            photoUrl: {
              size400x400: this.getUnitPhotoUrl(unit.id),
            },
            selectedCrewCombination: {
              ...unit.selectedCrewCombination,
              positions: unit.selectedCrewCombination.positions.map(
                (position) => {
                  return {
                    ...position,
                    assignedPersons: this.getAttendeesAssignedToPosition(
                      unit.id,
                      position.position.id
                    ),
                  };
                }
              ),
            },
          };
        })
        .sort((a, b) => a.priority - b.priority);
    },
    reportStart() {
      return {
        date: this.convertToDate(this.reportLocal.duration.start.timestamp),
        time: this.convertToTime(this.reportLocal.duration.start.timestamp),
      };
    },
  },
  methods: {
    addUnit() {
      const nextUnit = {
        ...this.helpers.unitSelector.nextUnit,
        selectedCrewCombination:
          this.helpers.unitSelector.nextUnitCrewCombination,
      };

      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId)
        .update(
          {
            "units.data": FieldValue.arrayUnion(nextUnit),
            "units.ids": FieldValue.arrayUnion(nextUnit.id),
            "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
            "meta.lastUpdatedBy": auth.currentUser.uid,
          },
          { merge: true }
        )
        .then(() => {
          this.helpers.unitSelector.model = false;
          this.helpers.unitSelector.nextUnit = null;
          this.helpers.unitSelector.nextUnitCrewCombination = null;
          this.helpers.unitSelector.loading = false;
        })
        .catch((error) => {
          console.log(error);
          alert("Fehler: Einsatzmittel konnte nicht hinzugefügt werden.");
          this.helpers.unitSelector.loading = false;
        });
    },
    getAttendanceEntryTimesText(attendanceEntry) {
      return `${attendanceEntry.duration.start.time} - ${
        attendanceEntry.duration.end.time
          ? attendanceEntry.duration.end.time
          : "Ende"
      }`;
    },
    // primaryBadgeUrl(member) {
    //   const primarySkillGroupId =
    //     this.memberManagementConfig.badgeDisplay.primarySkillGroupId || "";
    //   if (primarySkillGroupId) {
    //     const skillsOfPrimaryGroup = member.relevantSkills.data.filter(
    //       (skill) => skill.group.id === primarySkillGroupId
    //     );

    //     if (skillsOfPrimaryGroup.length === 0) {
    //       return "";
    //     }

    //     const highestPrimarySkill = skillsOfPrimaryGroup.reduce(
    //       (prev, current) => (prev.sortKey > current.sortKey ? prev : current),
    //       skillsOfPrimaryGroup[0]
    //     );

    //     return highestPrimarySkill.badgeUrl.size200x200;
    //   } else {
    //     return "";
    //   }
    // },
    getGroupHeaderText(assignablePerson) {
      const selector = this.helpers.personListSelector;

      const getNestedValue = (obj, path) => {
        const keys = path.split(".");
        let current = obj;

        for (let key of keys) {
          if (current[key] === undefined) {
            return "Fehler";
          }
          current = current[key];
        }

        return current;
      };

      return getNestedValue(assignablePerson, selector);
    },
    getUnitPhotoUrl(unitId) {
      if (!unitId) {
        console.error("unitId is not defined");
        return "";
      }

      if (!Array.isArray(this.units)) {
        console.error("units is not an array");
        return "";
      }

      const unit = this.units.find((unit) => unit.meta.id === unitId);
      if (!unit) {
        console.error(`No unit found with id ${unitId}`);
        return "";
      }

      if (!unit.photoUrl || !unit.photoUrl.size400x400) {
        console.error(`Photo URL not found for unit with id ${unitId}`);
        return "";
      }

      return unit.photoUrl.size400x400;
    },
    getAttendanceEntriesOfUser(userId) {
      return this.attendancesLocal.filter(
        (attendance) => attendance.user.uid === userId
      );
    },
    selectPerson(attendanceEntryId) {
      this.helpers.personChooserDialog.value = [];
      this.helpers.personChooserDialog.value.push(attendanceEntryId);
    },
    addPersonToPosition() {
      const unit = this.helpers.personChooserDialog.unit;
      const position = this.helpers.personChooserDialog.position;
      const assignedPosition = {
        unit: {
          title: unit.title,
          // crewCombination: unit.selectedCrewCombination.id, //TODO
          id: unit.id,
        },
        position: {
          shortTitle: position.position.shortTitle,
          id: position.position.id,
        },
        comboId: unit.id + "_" + position.position.id,
      };

      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId)
        .collection("dutyAttendances")
        .doc(this.helpers.personChooserDialog.value[0])
        .update(
          {
            assignedPosition: assignedPosition,
            "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
            "meta.lastUpdatedBy": auth.currentUser.uid,
          },
          { merge: true }
        )
        .then(() => {
          this.togglePersonChooserDialog(null, null);
          this.helpers.personChooserDialog.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.helpers.personChooserDialog.errorMessage = error.message;
          this.helpers.personChooserDialog.loading = false;
        });
    },
    togglePersonDetails(uid = null) {
      this.helpers.personDetailsDialog.dialogModel =
        !this.helpers.personDetailsDialog.dialogModel;
      alert(uid);
    },
    togglePersonChooserDialog(unit, position) {
      this.helpers.personChooserDialog.value = [];
      this.helpers.personChooserDialog.unit = unit;
      this.helpers.personChooserDialog.position = position;
      this.helpers.personChooserDialog.dialogModel =
        !this.helpers.personChooserDialog.dialogModel;
    },
    closeUnitSelector() {
      this.helpers.unitSelector.model = false;
      this.helpers.unitSelector.nextUnit = null;
      this.helpers.unitSelector.nextUnitCrewCombination = null;
      this.$refs.unitSelector.resetValidation();
    },
    getAttendeesAssignedToPosition(unitId, positionId) {
      return this.attendancesLocal.filter(
        (attendance) =>
          attendance.assignedPosition &&
          attendance.assignedPosition.unit &&
          attendance.assignedPosition.unit.id === unitId &&
          attendance.assignedPosition.position &&
          attendance.assignedPosition.position.id === positionId
      );
    },
    async resetUnit(unitId) {
      const attendanceIdsAssignedToUnit = this.attendancesLocal
        .filter(
          (attendance) =>
            attendance.assignedPosition &&
            attendance.assignedPosition.unit &&
            attendance.assignedPosition.unit.id === unitId
        )
        .map((attendance) => attendance.meta.id);

      const assignedPosition = {
        unit: {
          title: null,
          id: null,
        },
        position: {
          shortTitle: null,
          id: null,
        },
        comboId: null,
      };

      const batch = db.batch();
      const organizationId = this.$route.params.organizationId;
      const itemId = this.$route.params.itemId;

      attendanceIdsAssignedToUnit.forEach((attendanceId) => {
        const attendanceRef = db
          .collection("organizations")
          .doc(organizationId)
          .collection("dutyReports")
          .doc(itemId)
          .collection("dutyAttendances")
          .doc(attendanceId);

        batch.update(attendanceRef, {
          assignedPosition: assignedPosition,
          "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": auth.currentUser.uid,
        });
      });

      try {
        await batch.commit();
        this.showSnackbar("success", "Einheit erfolgreich zurückgesetzt");
      } catch (error) {
        console.log(error);
        this.showSnackbar("error", "Fehler beim Zurücksetzen der Einheit");
      }
    },

    async deleteUnit(unitId) {
      await this.resetUnit(unitId);

      const newUnitData = this.reportLocal.units.data.filter(
        (unit) => unit.id !== unitId
      );
      const newUnitIds = this.reportLocal.units.ids.filter(
        (id) => id !== unitId
      );

      const batch = db.batch();
      const reportRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId);

      batch.update(reportRef, {
        units: {
          ids: newUnitIds,
          data: newUnitData,
        },
        "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
        "meta.lastUpdatedBy": auth.currentUser.uid,
      });

      try {
        await batch.commit();
        this.showSnackbar("success", "Einheit erfolgreich gelöscht");
      } catch (error) {
        console.log(error);
        this.showSnackbar("error", "Fehler beim Löschen der Einheit");
      }
    },
    showSnackbar(color, message) {
      // this.helpers.snackbar.color = color;
      // this.helpers.snackbar.message = message;
      // this.helpers.snackbar.isVisible = true;
      console.log(color, message);
    },
    async deleteAllUnits() {
      // TODO: Delete from report and delete from all attendanceEntries
      var res = confirm(
        "Bist Du sicher, dass Du alle Einsatzmitel im Dienstbericht löschen möchtest?"
      );
      if (res == true) {
        // for every unitId in this.report.units.ids run deleteUnit(unitId)
        const unitIds = this.report.units.ids;

        try {
          await Promise.all(unitIds.map((unitId) => this.deleteUnit(unitId)));
          this.showSnackbar("success", "Alle Einheiten erfolgreich gelöscht");
        } catch (error) {
          console.log(error);
          this.showSnackbar("error", "Fehler beim Löschen der Einheiten");
        }
      }
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
  },
};
</script>

<style scoped lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
