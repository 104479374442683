<template>
  <div>
    <NavigationBar showBackBtn titel="Dienstmodus" :actions="actions">
      <template v-slot:before-actions>
        <v-menu
          v-model="helpers.responsibleSelector.model"
          :close-on-click="false"
          :close-on-content-click="false"
          offset-y
          :nudge-width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed v-bind="attrs" v-on="on" class="uppercase mr-2">
              <v-icon left>mdi-account</v-icon>Berichtspflicht
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="font-weight-medium subtitle-1">
              Berichtspflicht
            </v-card-title>

            <v-alert
              v-if="report.assignedTo.uid === currentUserUid"
              type="error"
              text
              dense
              class="mb-2 mx-4"
              icon="mdi-information"
            >
              Du bist bereits für diesen Bericht verantwortlich.
            </v-alert>
            <v-alert
              v-else
              type="info"
              text
              dense
              class="mb-2 mx-4"
              icon="mdi-information"
            >
              Du übernimmst die Berichtspflicht von
              {{ report.assignedTo.displayName }}.
            </v-alert>

            <v-card-actions>
              <v-btn text @click="helpers.responsibleSelector.model = false">
                Abbrechen
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                text
                :loading="helpers.responsibleSelector.loading"
                :disabled="report.assignedTo.uid === currentUserUid"
                @click="reassignReport"
              >
                <v-icon left>mdi-account-plus</v-icon>Berichtspflicht übernehmen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
    </NavigationBar>
    <section v-if="!helpers.loading" v-show="helpers.baseViewMode">
      <template>
        <v-container fluid>
          <v-row class="match-height">
            <v-col cols="12" sm="12" md="4">
              <v-card>
                <v-card-title class="subtitle-1 font-weight-medium">
                  Informationen
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="allowReportEdit"
                    depressed
                    small
                    @click="toggleViewMode('general')"
                    :disabled="report.isLocked"
                    ><v-icon left>mdi-pencil</v-icon>Bearbeiten</v-btn
                  >
                </v-card-title>

                <report-details-card-content
                  :report="report"
                  hideCardTitle
                ></report-details-card-content> </v-card
            ></v-col>
            <v-col cols="12" sm="12" md="8">
              <v-card>
                <attendance-overview-card-content
                  :report="report"
                  :dutyAttendances="dutyAttendances"
                ></attendance-overview-card-content>
              </v-card>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="4" hidden>
              <v-card>
                <incidents-counter-card-content></incidents-counter-card-content>
              </v-card>
            </v-col> -->
          </v-row>
          <v-row class="match-height">
            <v-col cols="12" sm="12" md="4">
              <v-card min-height="500px" max-height="600px">
                <v-card-title class="subtitle-1 font-weight-medium">
                  Anwesenheiten
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="allowReportEdit"
                    depressed
                    small
                    @click="toggleViewMode('attendance')"
                    :disabled="report.isLocked"
                    ><v-icon left>mdi-account-multiple-check</v-icon
                    >Erfassen</v-btn
                  >
                </v-card-title>
                <v-divider></v-divider>
                <v-data-table
                  dense
                  :items="dutyAttendances"
                  :headers="helpers.attendanceTable.headers"
                  fixed-header
                  height="460px"
                  single-expand
                  group-by="attendanceStatus"
                  sort-by="user.displayName"
                  show-expand
                  :expanded.sync="helpers.attendanceTable.expandedRows"
                  item-key="meta.id"
                  :mobile-breakpoint="0"
                  no-data-text="Keine Anwesenheiten erfasst"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                    <th :colspan="helpers.attendanceTable.headers.length - 1">
                      <v-icon @click="toggle" class="mr-2"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>

                      {{ getAttendanceStatusText(items[0].attendanceStatus) }}
                    </th>
                    <th><v-icon small>mdi-sigma</v-icon>{{ items.length }}</th>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-card-text v-if="item.attendanceStatus === '1'">
                        <div class="font-weight-medium">Startzeitpunkt:</div>
                        <div>
                          <v-chip
                            v-if="!item.duration.start.timestamp"
                            label
                            small
                            color="error"
                            class="my-1"
                            ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                            festgelegt</v-chip
                          ><span v-else>{{
                            timestampConverter(item.duration.start.timestamp)
                          }}</span>
                        </div>

                        <div class="font-weight-medium">Endzeitpunkt:</div>
                        <div>
                          <v-chip
                            v-if="!item.duration.end.timestamp"
                            label
                            small
                            color="error"
                            class="my-1"
                            ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                            festgelegt</v-chip
                          ><span v-else>{{
                            timestampConverter(item.duration.end.timestamp)
                          }}</span>
                        </div>
                        <div
                          v-if="item.assignedPosition.unit.title"
                          class="font-weight-medium"
                        >
                          Einsatzmittel:
                        </div>
                        <div v-if="item.assignedPosition.unit.title">
                          {{ item.assignedPosition.unit.title }}
                        </div>
                        <div
                          v-if="item.assignedPosition.position.shortTitle"
                          class="font-weight-medium"
                        >
                          Position:
                        </div>
                        <div v-if="item.assignedPosition.position.shortTitle">
                          {{ item.assignedPosition.position.shortTitle }}
                        </div>
                        <div class="font-weight-medium">Abrechnungsart:</div>
                        <div>
                          <v-chip
                            v-if="!item.payrollType.title"
                            label
                            small
                            color="error"
                            class="my-1"
                            ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                            festgelegt</v-chip
                          >
                          <v-chip v-else small outlined class="mt-1">{{
                            item.payrollType.title
                          }}</v-chip>
                        </div>
                        <div v-if="item.note" class="font-weight-medium">
                          Bemerkung:
                        </div>
                        <div v-if="item.note">{{ item.note }}</div>
                      </v-card-text>
                      <v-card-text v-else>
                        <div v-if="!item.note">
                          Keine ergänzenden Informationen vorhanden
                        </div>
                        <div v-if="item.note" class="font-weight-medium">
                          Bemerkung:
                        </div>
                        <div v-if="item.note">{{ item.note }}</div>
                      </v-card-text>
                    </td>
                  </template>
                  <template v-slot:[`item.attendanceStatusChip`]="{ item }">
                    <attendance-status-chip
                      :status="item.attendanceStatus"
                      small
                    ></attendance-status-chip>
                  </template>
                </v-data-table> </v-card
            ></v-col>
            <v-col cols="12" sm="12" md="8">
              <v-card>
                <v-card-title class="subtitle-1 font-weight-medium">
                  Einsatzmittel
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="allowReportEdit"
                    depressed
                    small
                    @click="toggleViewMode('unit')"
                    :disabled="report.isLocked"
                    ><v-icon left>mdi-fire-truck</v-icon>Einteilen</v-btn
                  >
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row dense v-if="unitsDisplay.length">
                    <v-col
                      v-for="unit in unitsDisplay"
                      :key="unit.id"
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                    >
                      <report-unit-card :unit="unit"></report-unit-card>
                    </v-col>
                  </v-row>
                  <v-row
                    v-else
                    class="grey--text text-center d-flex align-center justify-center mt-2"
                  >
                    Keine Einsatzmitel erfasst</v-row
                  >
                </v-card-text>
              </v-card></v-col
            >
            <!-- <v-col cols="12" sm="12" md="4">
              <v-card>
                <incidents-news-feed-card-content></incidents-news-feed-card-content>
              </v-card>
            </v-col> -->
          </v-row>
        </v-container>
      </template>
    </section>
    <Loading v-else overlay></Loading>

    <section>
      <custom-dialog
        v-if="helpers.generalView.dialogModel"
        @close="toggleViewMode('general')"
        title="Dienstinformationen"
        width="xl"
        :errorMessage="helpers.generalView.errorMessage"
        :loading="helpers.generalView.loading"
        hideActionBtn
        hideCloseBtn
        show
        fullscreen
        fullscreenOnMobile
        appBarStyling
      >
        <template v-slot:appBarButtons>
          <v-btn
            depressed
            color="success"
            @click="updateReport('general')"
            class="uppercase"
            ><v-icon left>mdi-content-save</v-icon>Speichern</v-btn
          >
        </template>
        <template v-slot:content>
          <v-form v-model="helpers.generalView.formIsValid">
            <!-- @submit.prevent="startDataImport()" -->
            <report-live-general-view
              :report="report"
              :duration="duration"
            ></report-live-general-view>
          </v-form>
        </template>
      </custom-dialog>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.attendanceView.dialogModel"
        @close="toggleViewMode('attendance')"
        title="Anwesenheiten"
        width="xl"
        :errorMessage="helpers.attendanceView.errorMessage"
        :loading="helpers.attendanceView.loading"
        hideActionBtn
        hideCloseBtn
        show
        fullscreen
        fullscreenOnMobile
        appBarStyling
      >
        <template v-slot:appBarButtons>
          <v-btn
            depressed
            color="success"
            @click="updateReport('attendance')"
            class="uppercase"
            ><v-icon left>mdi-content-save</v-icon>Speichern</v-btn
          >
        </template>
        <template v-slot:content>
          <v-form v-model="helpers.attendanceView.formIsValid">
            <!-- @submit.prevent="startDataImport()" -->
            <report-live-attendance-view
              :report="report"
              :units="units"
              :attendancesObject="attendancesObject"
            ></report-live-attendance-view>
          </v-form>
        </template>
      </custom-dialog>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.unitView.dialogModel"
        @close="toggleViewMode('unit')"
        title="Einsatzmittel"
        width="xl"
        :errorMessage="helpers.unitView.errorMessage"
        :loading="helpers.unitView.loading"
        btnText="Auswahl importieren"
        hideActionBtn
        hideCloseBtn
        fullscreen
        fullscreenOnMobile
        appBarStyling
      >
        <template v-slot:appBarButtons>
          <v-btn
            depressed
            color="success"
            @click="updateReport('unit')"
            class="uppercase"
            ><v-icon left>mdi-content-save</v-icon>Speichern</v-btn
          >
        </template>
        <template v-slot:content>
          <v-form v-model="helpers.unitView.formIsValid">
            <!-- @submit.prevent="startDataImport()" -->
            <report-live-unit-view
              :report="report"
              :units="units"
              :attendances="dutyAttendances"
              :addableAttendees="addableAttendees"
            ></report-live-unit-view>
          </v-form>
        </template>
      </custom-dialog>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.setEndTimeDialog.dialogModel"
        @close="toggleSetEndTimeDialog()"
        title="Endzeitpunkt festlegen"
        width="s"
        :errorMessage="helpers.setEndTimeDialog.errorMessage"
        :loading="helpers.setEndTimeDialog.loading"
        :btnAction="setReportEndTime"
        btnText="Bestätigen"
        :btnDisabled="!helpers.setEndTimeDialog.formIsValid"
        fullscreenOnMobile
      >
        <template v-slot:content>
          <v-form
            v-model="helpers.setEndTimeDialog.formIsValid"
            ref="closeReportForm"
            @submit.prevent="setReportEndTime()"
          >
            <v-card outlined>
              <v-card-subtitle
                ><v-icon left small class="mr-2">mdi-flag-checkered</v-icon
                >Endzeitpunkt festlegen</v-card-subtitle
              >
              <v-card-text>
                <v-row>
                  <v-col cols="auto">
                    <v-btn depressed @click="setNowAsEnd()"
                      ><v-icon left>mdi-clock-in</v-icon>Jetzt einsetzen</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="duration.end.date"
                      type="date"
                      label="Enddatum"
                      hint="DD.MM.YYYY"
                      persistent-hint
                      outlined
                      :rules="[rules.required, rules.startBeforeEnd]"
                      prepend-inner-icon="mdi-calendar-end"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="duration.end.time"
                      type="time"
                      label="Endzeit"
                      hint="HH:MM"
                      persistent-hint
                      outlined
                      :rules="[rules.required, rules.startBeforeEnd]"
                      prepend-inner-icon="mdi-clock-end"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-alert text color="primary" type="info" dense>
                      <div class="title">Hinweis</div>
                      <div class="mt-2">
                        Der Endzeitpunkt wird automatisch auf alle
                        Anwesenheitseinträge angewendet, für die noch kein
                        Endzeitpunkt festgelegt ist.
                      </div>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </template>
      </custom-dialog>
      <custom-dialog
        v-if="helpers.closeReportDialog.dialogModel"
        @close="toggleCloseReportDialog()"
        :title="
          report.status === 'inProgress'
            ? 'Dienst beenden'
            : 'Korrektur abschließen'
        "
        width="xs"
        :errorMessage="helpers.closeReportDialog.errorMessage"
        :loading="helpers.closeReportDialog.loading"
        :btnAction="closeReport"
        btnText="Bestätigen"
        :btnDisabled="!reportIsComplete"
      >
        <template v-slot:content>
          <v-alert
            v-if="!reportIsComplete"
            type="error"
            text
            dense
            class="mb-2"
            hidden
          >
            <div class="text-h6">Fehler</div>
            <div>
              Folgende Angaben überprüfen oder hinzufügen:
              <li v-if="!report.title">Bezeichnung</li>
              <li v-if="!report.assignedTo.uid">Berichtsverantwortlich</li>
              <li v-if="!report.duration.start.timestamp">Startzeitpunkt</li>
              <li v-if="!report.duration.end.timestamp">Endzeitpunkt</li>
              <li v-if="!report.location.name">Ort</li>
              <li v-if="!report.payrollType.id">Primäre Abrechnungsart</li>
              <li v-if="!attendanceEntriesAreComplete">
                Erfasste Anwesenheiten
              </li>
            </div>
          </v-alert>
          <v-alert type="info" text dense class="mb-2">
            Nachdem die Bearbeitung geschlossen wurde, wird der Dienstbericht
            zur Überprüfung freigegeben. In diesem Status kann der Bericht nicht
            mehr bearbeitet werden.
          </v-alert>
        </template>
      </custom-dialog>
    </section>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      :color="helpers.snackbar.color"
      ><v-icon class="mr-1">mdi-check-circle</v-icon>
      {{ helpers.snackbar.message }}
    </v-snackbar>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db, functions, auth, FieldValue, Timestamp } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Loading from "@/components/_system/helpers/Loading.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import AttendanceStatusChip from "@/components/duty-management/AttendanceStatusChip.vue";
import AttendanceOverviewCardContent from "@/components/duty-management/AttendanceOverviewCardContent.vue";
import ReportLiveGeneralView from "@/components/duty-management/ReportLiveGeneralView.vue";
import ReportLiveAttendanceView from "@/components/duty-management/ReportLiveAttendanceView.vue";
import ReportLiveUnitView from "@/components/duty-management/ReportLiveUnitView.vue";
import ReportUnitCard from "@/components/duty-management/ReportUnitCard.vue";
// import IncidentsCounterCardContent from "@/components/duty-management/IncidentsCounterCardContent.vue";
// import IncidentsNewsFeedCardContent from "@/components/duty-management/IncidentsNewsFeedCardContent.vue";
import ReportDetailsCardContent from "@/components/duty-management/ReportDetailsCardContent.vue";
import {
  DUTY_MANAGEMENT_REPORTS_create,
  DUTY_MANAGEMENT_REPORTS_review,
} from "@/data/permission-types.js";
import { ADMIN, AVAILABILITY, MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_UNITS,
  GET_USER_LIST,
  GET_SKILL_GROUPS,
  GET_SKILLS,
} from "@/store/action-types.js";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";

export default {
  name: "duty-management-reports-live",
  components: {
    NavigationBar,
    Loading,
    CustomDialog,
    AttendanceStatusChip,
    AttendanceOverviewCardContent,
    // IncidentsCounterCardContent,
    // IncidentsNewsFeedCardContent,
    ReportLiveGeneralView,
    ReportLiveAttendanceView,
    ReportLiveUnitView,
    ReportUnitCard,
    ReportDetailsCardContent,
    SupportTools,
  },
  data() {
    return {
      currentUserUid: auth.currentUser.uid,
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        startBeforeEnd: () =>
          (!this.fieldsAreFilled &&
            this.convertToTimestamp(
              this.report.duration.start.date,
              this.report.duration.start.time
            ).valueOf() <
              this.convertToTimestamp(
                this.duration.end.date,
                this.duration.end.time
              ).valueOf()) ||
          "Start muss vor Ende liegen",
      },
      helpers: {
        dataIsLoading: false,
        baseViewMode: true,
        responsibleSelector: {
          model: false,
          loading: false,
          formIsValid: false,
        },
        snackbar: {
          isVisible: false,
          color: "",
          message: "",
        },
        attendanceTable: {
          headers: [
            { text: "Name", value: "user.displayName", divider: true },
            {
              text: "Anwesenheit",
              value: "attendanceStatusChip",
              width: "10%",
              divider: true,
            },
            { text: "", value: "data-table-expand", sortable: false },
          ],
          expandedRows: [],
        },
        generalView: {
          dialogModel: false,
          errorMessage: "",
          loading: false,
        },
        attendanceView: {
          dialogModel: false,
          errorMessage: "",
          loading: false,
        },
        unitView: {
          dialogModel: false,
          errorMessage: "",
          loading: false,
        },
        setEndTimeDialog: {
          dialogModel: false,
          formIsValid: false,
          errorMessage: "",
          loading: false,
        },
        closeReportDialog: {
          dialogModel: false,
          errorMessage: "",
          loading: false,
        },
      },
      duration: {
        start: {
          date: "",
          time: "",
        },
        end: {
          date: "",
          time: "",
        },
      },
      dutyAttendances: [],
      attendancesObject: { data: [] },
      report: {
        title: "",
        location: {
          name: "",
        },
        duration: {
          start: { timestamp: null, weekday: null, date: null, time: null },
          end: {
            timestamp: null,
            isOpen: false,
            weekday: null,
            date: null,
            time: null,
          },
          inHours: 0,
          inMinutes: 0,
        },
        payrollType: {
          id: "",
          title: "",
          formula: "",
        },
        type: {
          id: "",
          title: "",
          subtitle: "",
          icon: "",
        },
        assignedTo: {
          uid: "",
          displayName: "",
        },
        assignSkillToParticipants: {
          isEnabled: false,
          skillId: null,
        },
        status: "inProgress",
        isLocked: false,
        units: {
          ids: [],
          data: [],
        },
        incident: {
          number: null,
          code: null,
        },
        attendances: {
          personIds: [],
          presentPersonIds: [],
          absentPersonIds: [],
          excusedPersonIds: [],
          unexcusedPersonIds: [],
          count: {
            total: 0,
            totalPresent: 0,
            totalAbsent: 0,
            totalExcused: 0,
            totalUnexcused: 0,
            totalGuests: 0,
          },
        },
        safetyPrecautionsDocumentation: "",
        trainingSubject: "",
        tasks: [],
        logs: [],
      },
    };
  },
  computed: {
    actions() {
      if (
        this.report.duration.end.timestamp &&
        (this.report.status === "inProgress" ||
          this.report.status === "inCorrection")
      ) {
        return [
          {
            title: "Abschließen",
            permission: false,
            customPermission: this.allowReportEdit,
            icon: "mdi-flag-checkered",
            actionStyle: "textBtnSubmit",
            color: this.reportIsComplete ? "success" : "error",
            function: this.toggleCloseReportDialog,
          },
        ];
      } else if (this.report.status === "inProgress") {
        return [
          {
            title: "Dienstende",
            permission: false,
            customPermission: this.allowReportEdit,
            icon: "mdi-clock-end",
            actionStyle: "textBtnSubmit",
            color: "warning",
            function: this.toggleSetEndTimeDialog,
          },
        ];
      } else {
        return [];
      }
    },
    fieldsAreFilled() {
      if (
        !this.report.duration.start.date === "" &&
        !this.report.duration.start.time === "" &&
        !this.duration.end.date === "" &&
        !this.duration.end.time === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    allowReportEdit() {
      const reportInProgress = this.report.status === "inProgress";
      const reportInCorrection = this.report.status === "inCorrection";
      if (reportInProgress) {
        const currentUserIsAssignedToReport =
          this.report.assignedTo.uid === this.currentUserUid;
        const reportEditPermission = this.$store.getters[
          "organization/checkPermissionByID"
        ](`${DUTY_MANAGEMENT_REPORTS_create}`);
        return currentUserIsAssignedToReport && reportEditPermission;
      } else if (reportInCorrection) {
        const currentUserIsAssignedToCorrection =
          this.report.correctedBy.uid === this.currentUserUid;
        const reportReviewPermission = this.$store.getters[
          "organization/checkPermissionByID"
        ](`${DUTY_MANAGEMENT_REPORTS_review}`);
        return currentUserIsAssignedToCorrection && reportReviewPermission;
      } else {
        return false;
      }
    },
    dutyManagementConfig() {
      return this.$store.state.organization.activeOrganization.config
        .dutyManagement;
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        { title: "report", data: this.report },
        { title: "dutyAttendances", data: this.dutyAttendances },
        { title: "units", data: this.units },
      ];
    },
    reportIsComplete() {
      const reportIsComplete =
        this.report.title &&
        this.report.assignedTo.uid &&
        this.report.duration.start.timestamp &&
        this.report.duration.end.timestamp &&
        this.report.location.name &&
        this.report.payrollType.id;

      return reportIsComplete && this.attendanceEntriesAreComplete;
    },
    attendanceEntriesAreComplete() {
      return this.dutyAttendances.every((attendanceEntry) => {
        if (
          attendanceEntry.user.uid &&
          attendanceEntry.user.displayName &&
          (attendanceEntry.attendanceStatus === "2" ||
            attendanceEntry.attendanceStatus === "3")
        ) {
          return true;
        }
        const startDateTime =
          attendanceEntry.duration.start.date &&
          attendanceEntry.duration.start.time;
        const endDateTime =
          attendanceEntry.duration.end.date &&
          attendanceEntry.duration.end.time;

        if (!startDateTime || !endDateTime) {
          return false;
        }

        const startTimestamp = new Date(
          attendanceEntry.duration.start.date +
            " " +
            attendanceEntry.duration.start.time
        ).getTime();
        const endTimestamp = new Date(
          attendanceEntry.duration.end.date +
            " " +
            attendanceEntry.duration.end.time
        ).getTime();

        return (
          attendanceEntry.user.uid &&
          attendanceEntry.user.displayName &&
          startTimestamp &&
          endTimestamp &&
          endTimestamp >= startTimestamp &&
          attendanceEntry.payrollType.id
        );
      });
    },
    units() {
      return this.$store.state.availability.units;
    },
    unitsDisplay() {
      const units = this.report.units.data;
      return units
        .map((unit) => {
          return {
            ...unit,
            photoUrl: {
              size400x400: this.getUnitPhotoUrl(unit.id),
            },
            selectedCrewCombination: {
              ...unit.selectedCrewCombination,
              positions: unit.selectedCrewCombination.positions.map(
                (position) => {
                  return {
                    ...position,
                    assignedPersons: this.getAttendeesAssignedToPosition(
                      unit.id,
                      position.position.id
                    ),
                  };
                }
              ),
            },
          };
        })
        .sort((a, b) => a.priority - b.priority);
    },
    addableAttendees() {
      return this.$store.state.admin.membershipsPublic;
    },
  },
  created() {
    this.initialize();
  },
  beforeDestroy() {},
  methods: {
    async initialize() {
      try {
        await this.initializeGeneralFirestoreListener();
        await this.initializeAttendancesFirestoreListener();
        await this.$store.dispatch(`${AVAILABILITY}${GET_UNITS}`, {
          organizationId: this.$route.params.organizationId,
        });
        await this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "active",
          },
        });
        await this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_SKILL_GROUPS}`, {
          organizationId: this.$route.params.organizationId,
        });
        await this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_SKILLS}`, {
          organizationId: this.$route.params.organizationId,
        });

        this.helpers.dataIsLoading = false;
      } catch (error) {
        console.error("Error initializing:", error);
        throw new Error(`Initialization failed: ${error.message}`);
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    initializeGeneralFirestoreListener() {
      const reportRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId);

      return reportRef.onSnapshot((doc) => {
        if (!doc.exists) {
          throw new Error("Dienstbericht nicht gefunden.");
        }
        this.report = doc.data();
        this.report.duration.start.timestampToDate = doc
          .data()
          .duration.start.timestamp.toDate();

        this.duration.start.date = this.convertToDate(
          this.report.duration.start.timestamp
        );
        this.duration.start.time = this.convertToTime(
          this.report.duration.start.timestamp
        );

        this.duration.end.date = this.convertToDate(
          this.report.duration.end.timestamp
        );
        this.duration.end.time = this.convertToTime(
          this.report.duration.end.timestamp
        );
        this.report.duration.end.timestampToDate = doc.data().duration.end
          .timestamp
          ? doc.data().duration.end.timestamp.toDate()
          : null;
      });
    },
    initializeAttendancesFirestoreListener() {
      const reportRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId)
        .collection("dutyAttendances");
      return reportRef.onSnapshot((querySnapshot) => {
        var dutyAttendances = [];
        querySnapshot.forEach((doc) => {
          dutyAttendances.push(doc.data());
        });
        this.dutyAttendances = dutyAttendances;
        this.attendancesObject.data = dutyAttendances;
      });
    },
    getAttendeesAssignedToPosition(unitId, positionId) {
      return this.dutyAttendances.filter(
        (attendance) =>
          attendance.assignedPosition &&
          attendance.assignedPosition.unit &&
          attendance.assignedPosition.unit.id === unitId &&
          attendance.assignedPosition.position &&
          attendance.assignedPosition.position.id === positionId
      );
    },
    reassignReport() {
      this.helpers.responsibleSelector.loading = true;
      const callFunction = functions.httpsCallable(
        "dutyManagement-reassignReport"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        dutyReportId: this.report.meta.id,
        reassignTo: {
          uid: auth.currentUser.uid,
        },
        reassignMode: "takeOver",
      })
        .then(() => {
          this.helpers.responsibleSelector.loading = false;
          this.helpers.responsibleSelector.model = false;
          this.showSnackbar("success", "Du bist nun berichtsverantwortlich.");
        })
        .catch((error) => {
          this.helpers.responsibleSelector.loading = false;
          this.helpers.responsibleSelector.model = false;
          this.showSnackbar("error", error.message);
          console.error("Error reassigning report: ", error);
        });
    },
    setNowAsEnd() {
      const now = new Date();
      this.duration.end.date = now.toISOString().split("T")[0];
      this.duration.end.time = now
        .toTimeString()
        .split(" ")[0]
        .split(":")
        .slice(0, 2)
        .join(":");
    },
    setReportEndTime() {
      this.helpers.setEndTimeDialog.loading = true;

      const batch = db.batch();
      const organizationId = this.$route.params.organizationId;
      const itemId = this.$route.params.itemId;

      // 1. Aktualisieren Sie die Dauer im Dokument "dutyReport"
      const reportRef = db
        .collection("organizations")
        .doc(organizationId)
        .collection("dutyReports")
        .doc(itemId);

      batch.update(reportRef, {
        duration: {
          ...this.report.duration,
          end: {
            ...this.report.duration.end,
            time: this.duration.end.time,
            date: this.duration.end.date,
            timestamp: this.convertToTimestamp(
              this.duration.end.date,
              this.duration.end.time
            ),
          },
        },
        "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
        "meta.lastUpdatedBy": auth.currentUser.uid,
      });

      // 2. Filtern Sie die "dutyAttendances", um nur diejenigen zu aktualisieren, bei denen kein Endzeitpunkt angegeben ist
      const attendancesToUpdate = this.dutyAttendances.filter(
        (attendance) =>
          !attendance.duration.end.timestamp &&
          (!attendance.duration.end.time || !attendance.duration.end.date)
      );

      // 3. Aktualisieren Sie die Dauer in den gefilterten "dutyAttendances"
      attendancesToUpdate.forEach((attendance) => {
        const attendanceRef = db
          .collection("organizations")
          .doc(organizationId)
          .collection("dutyReports")
          .doc(itemId)
          .collection("dutyAttendances")
          .doc(attendance.meta.id);

        batch.update(attendanceRef, {
          duration: {
            ...attendance.duration,
            end: {
              time: this.duration.end.time,
              date: this.duration.end.date,
              timestamp: this.convertToTimestamp(
                this.duration.end.date,
                this.duration.end.time
              ),
            },
          },
          "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": auth.currentUser.uid,
        });
      });

      // 4. Führen Sie die Batch-Operation aus und behandeln Sie die Ergebnisse
      return batch
        .commit()
        .then(() => {
          this.toggleSetEndTimeDialog();
          this.helpers.setEndTimeDialog.loading = false;
          this.toggleCloseReportDialog();
          this.showSnackbar("success", "Endzeitpunkt festgelegt.");
        })
        .catch((error) => {
          console.log(error);
          this.helpers.setEndTimeDialog.loading = false;
          this.helpers.setEndTimeDialog.errorMessage = error.message;
        });
    },
    closeReport() {
      this.helpers.closeReportDialog.loading = true;
      const callFunction = functions.httpsCallable(
        "dutyManagement-closeReport"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        dutyReportId: this.report.meta.id,
        reason: "",
      })
        .then(() => {
          this.helpers.closeReportDialog.loading = false;
          this.helpers.closeReportDialog.model = false;
          this.$router.push({
            name: "duty-management-reports-details",
            params: {
              organizationId: this.$route.params.organizationId,
              item: this.$route.params.itemId,
            },
          });
        })
        .catch((error) => {
          this.helpers.closeReportDialog.loading = false;
          this.helpers.closeReportDialog.errorMessage = error.message;
          console.error("Error reassigning report: ", error);
        });
    },
    getUnitPhotoUrl(unitId) {
      if (!unitId) {
        console.error("unitId is not defined");
        return "";
      }

      if (!Array.isArray(this.units)) {
        console.error("units is not an array");
        return "";
      }

      const unit = this.units.find((unit) => unit.meta.id === unitId);
      if (!unit) {
        console.error(`No unit found with id ${unitId}`);
        return "";
      }

      if (!unit.photoUrl || !unit.photoUrl.size400x400) {
        console.error(`Photo URL not found for unit with id ${unitId}`);
        return "";
      }

      return unit.photoUrl.size400x400;
    },
    getAttendanceStatusText(statusId) {
      switch (statusId) {
        case "1":
          return "Anwesend";
        case "2":
          return "Entschuldigt";
        case "3":
          return "Unentschuldigt";
        default:
          return "Fehler";
      }
    },
    toggleSetEndTimeDialog() {
      this.helpers.setEndTimeDialog.dialogModel =
        !this.helpers.setEndTimeDialog.dialogModel;
      this.duration.end.date = "";
      this.duration.end.time = "";
    },
    toggleCloseReportDialog() {
      this.helpers.closeReportDialog.dialogModel =
        !this.helpers.closeReportDialog.dialogModel;
      this.helpers.closeReportDialog.errorMessage = "";
    },
    toggleViewMode(viewMode) {
      if (viewMode === "general") {
        this.helpers.generalView.dialogModel =
          !this.helpers.generalView.dialogModel;
      } else if (viewMode === "attendance") {
        this.helpers.attendanceView.dialogModel =
          !this.helpers.attendanceView.dialogModel;
      } else if (viewMode === "unit") {
        this.helpers.unitView.dialogModel = !this.helpers.unitView.dialogModel;
      }
      this.helpers.baseViewMode = !this.helpers.baseViewMode;
    },
    setLoadingAndError(view, loading, errorMessage) {
      this.helpers[view].loading = loading;
      this.helpers[view].errorMessage = errorMessage;
    },
    updateReport(mode) {
      var updatedReportData = {};
      const batch = db.batch();

      if (mode === "general") {
        this.setLoadingAndError("generalView", true, "");

        updatedReportData = {
          title: this.report.title,
          location: {
            name: this.report.location.name,
          },
          duration: {
            start: {
              ...this.report.duration.start,
              timestamp:
                this.duration.start.date && this.duration.start.time
                  ? this.convertToTimestamp(
                      this.duration.start.date,
                      this.duration.start.time
                    )
                  : null,
            },
            end: {
              ...this.report.duration.end,
              isOpen:
                this.duration.end.date && this.duration.end.time
                  ? this.report.duration.end.isOpen
                  : true,
              timestamp:
                this.duration.end.date && this.duration.end.time
                  ? this.convertToTimestamp(
                      this.duration.end.date,
                      this.duration.end.time
                    )
                  : null,
            },
          },
          payrollType: {
            id: this.report.payrollType.id,
            title: this.report.payrollType.title,
          },
          customVariables: this.report.customVariables,
          type: {
            id: this.report.type.id,
            title: this.report.type.title,
            shortTitle: this.report.type.shortTitle,
            color: this.report.type.color,
            sortKey: this.report.type.sortKey,
            icon: this.report.type.icon,
          },
          safetyPrecautionsDocumentation:
            this.report.safetyPrecautionsDocumentation,
          trainingSubject: this.report.trainingSubject,
          tasks: [],
          logs: [],
          incident: {
            number: this.report.incident.number,
            code: this.report.incident.code,
          },
          notes: this.report.notes,
        };
      } else if (mode === "attendance") {
        this.setLoadingAndError("attendanceView", true, "");

        //1: Update dutyAttendances
        this.dutyAttendances.forEach((attendance) => {
          const attendanceRef = db
            .collection("organizations")
            .doc(this.$route.params.organizationId)
            .collection("dutyReports")
            .doc(this.$route.params.itemId)
            .collection("dutyAttendances")
            .doc(attendance.meta.id);
          // console.log(
          //   "ATTENDANCE:" +
          //     attendance.user.displayName +
          //     attendance.attendanceStatus
          // );
          // console.log(attendance);
          const attendanceUpdateData = {
            ...attendance,
            duration: {
              ...attendance.duration,
              start: {
                ...attendance.duration.start,
                timestamp: this.convertToTimestamp(
                  attendance.duration.start.date,
                  attendance.duration.start.time
                ),
              },
              end: {
                ...attendance.duration.end,
                timestamp:
                  attendance.duration.end.date && attendance.duration.end.time
                    ? this.convertToTimestamp(
                        attendance.duration.end.date,
                        attendance.duration.end.time
                      )
                    : null,
              },
            },
          };

          batch.set(attendanceRef, attendanceUpdateData, { merge: true });
        });
      } else if (mode === "unit") {
        this.setLoadingAndError("unitView", true, "");

        updatedReportData = {
          units: {
            ids: this.report.units.ids,
            data: this.report.units.data,
          },
        };
      }

      const reportRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId);

      batch.update(reportRef, {
        ...updatedReportData,
        "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
        "meta.lastUpdatedBy": auth.currentUser.uid,
      });

      return batch
        .commit()
        .then(() => {
          this.toggleViewMode(mode);
          this.showSnackbar("success", "Aktualisierung erfolgreich");
        })
        .catch((error) => {
          console.log(error);
          this.showSnackbar("error", "Fehler bei Aktualisierung");
        });
    },
    showSnackbar(color, message) {
      this.helpers.snackbar.color = color;
      this.helpers.snackbar.message = message;
      this.helpers.snackbar.isVisible = true;
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    convertToDate(timestamp) {
      if (!timestamp) {
        return null;
      }
      timestamp = timestamp.toDate();
      return (
        timestamp.getFullYear() +
        "-" +
        (timestamp.getMonth() + 1 < 10 ? "0" : "") +
        (timestamp.getMonth() + 1) +
        "-" +
        (timestamp.getDate() < 10 ? "0" : "") +
        timestamp.getDate()
      );
    },
    convertToTime(timestamp) {
      if (!timestamp) {
        return null;
      }
      timestamp = timestamp.toDate();
      return (
        (timestamp.getHours() < 10 ? "0" : "") +
        timestamp.getHours() +
        ":" +
        (timestamp.getMinutes() < 10 ? "0" : "") +
        timestamp.getMinutes()
      );
    },
    calculateDuration(startTimestamp, endTimestamp, unit = "minutes") {
      const startTime = startTimestamp.toDate();
      const endTime = endTimestamp.toDate();

      const durationInMilliseconds = endTime - startTime;

      if (unit === "minutes") {
        // Calculate and return duration in minutes
        return Math.floor(durationInMilliseconds / (60 * 1000));
      } else if (unit === "hours") {
        // Calculate and return duration in hours
        return durationInMilliseconds / (60 * 60 * 1000);
      } else {
        // Invalid unit specified
        return null;
      }
    },
    getISODay(timestamp) {
      // Ensure timestamp is a valid Firebase Timestamp
      if (!timestamp || !timestamp.toDate) {
        return null;
      }

      // Get a JavaScript Date object from the Firebase Timestamp
      const date = timestamp.toDate();

      // Get the ISO day (1 for Monday, 7 for Sunday)
      let isoDay = date.getUTCDay();

      // Adjust Sunday (0) to 7 to match ISO 8601 standard
      if (isoDay === 0) {
        isoDay = 7;
      }

      return isoDay;
    },
  },
};
</script>

<!-- <style scoped lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
} -->
