<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-subtitle
            ><v-icon left small class="mr-2">mdi-card-text</v-icon
            >Allgemein</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model.trim="reportLocal.title"
                  label="Bezeichnung"
                  required
                  :rules="[rules.required]"
                  outlined
                  :disabled="reportLocal.eventId ? true : false"
                  :hint="
                    reportLocal.eventId ? 'Von Veranstaltung vorgegeben' : ''
                  "
                  :persistent-hint="reportLocal.eventId ? true : false"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model.trim="reportLocal.location.name"
                  label="Ort"
                  required
                  :rules="[rules.required]"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn depressed @click="setNow('start')"
                  ><v-icon left>mdi-clock-in</v-icon>Jetzt einsetzen</v-btn
                >
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="durationLocal.start.date"
                  type="date"
                  label="Startdatum"
                  hint="DD.MM.YYYY"
                  outlined
                  required
                  prepend-inner-icon="mdi-calendar-start"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="durationLocal.start.time"
                  label="Startzeit"
                  hint="HH:MM"
                  outlined
                  type="time"
                  prepend-inner-icon="mdi-clock-start"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="reportLocal.duration.end.isOpen"
                  label="Endzeitpunkt"
                  mandatory
                  hide-details="auto"
                  class="mt-0 pt-0"
                  :row="$vuetify.breakpoint.xs ? false : true"
                >
                  <v-radio
                    label="Noch nicht abgeschlossen"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    label="Endzeitpunkt festlegen"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="!reportLocal.duration.end.isOpen" cols="auto">
                <v-btn depressed @click="setNow('end')"
                  ><v-icon left>mdi-clock-in</v-icon>Jetzt einsetzen</v-btn
                >
              </v-col>
              <v-col
                v-if="!reportLocal.duration.end.isOpen"
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="durationLocal.end.date"
                  type="date"
                  label="Enddatum"
                  hint="DD.MM.YYYY"
                  outlined
                  required
                  prepend-inner-icon="mdi-calendar-end"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="!reportLocal.duration.end.isOpen"
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="durationLocal.end.time"
                  type="time"
                  label="Endzeit"
                  hint="HH:MM"
                  outlined
                  prepend-inner-icon="mdi-clock-end"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-subtitle
            ><v-icon left small class="mr-2">mdi-card-text</v-icon
            >Anlass</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-select
                  v-model="reportLocal.type"
                  :items="dutyTypes"
                  label="Dienstsart"
                  outlined
                  hide-details="auto"
                  required
                  disabled
                  item-value="id"
                  item-text="title"
                >
                  <template v-slot:selection="{ item }">
                    <duty-type-chip :dutyType="item"> </duty-type-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.trim="reportLocal.incident.code"
                  label="Alarmierungsstichwort"
                  outlined
                  clearable
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.trim="reportLocal.incident.number"
                  label="Einsatznummer"
                  outlined
                  clearable
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-subtitle
            ><v-icon left small class="mr-2">mdi-cash-multiple</v-icon
            >Abrechnung</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="reportLocal.payrollType"
                  :items="payrollTypes"
                  label="Primäre Abrechnungsart"
                  item-text="title"
                  item-description="formula"
                  item-disabled="disabled"
                  outlined
                  hide-details="auto"
                  return-object
                  required
                  :rules="[rules.required]"
                >
                </v-select>
              </v-col>
              <v-col
                v-for="requiredInputField in requiredInputFields"
                :key="requiredInputField.id"
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="reportLocal.customVariables[requiredInputField.id]"
                  :type="requiredInputField.options.type"
                  :label="requiredInputField.options.label"
                  outlined
                  hide-details="auto"
                  :hint="requiredInputField.options.hint"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-subtitle
            ><v-icon left small class="mr-2">mdi-dots-horizontal-circle</v-icon
            >Sonstiges</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="12"
                ><v-text-field
                  v-model.trim="reportLocal.safetyPrecautionsDocumentation"
                  label="UVV-Dokumentation"
                  hide-details="auto"
                  outlined
                  clearable
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  v-model.trim="reportLocal.trainingSubject"
                  label="Ausbildungsthema"
                  hide-details="auto"
                  clearable
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="reportLocal.assignSkillToParticipants.isEnabled"
                  label="Allen Teilnehmenden eine Qualifikation zuweisen"
                  persistent-hint
                  disabled
                  hint="Alle Teilnehmenden erhalten nach Abschluss des Berichts die ausgewählte Qualifikation in ihrem Profil mit Nachweisdatum zugewiesen"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model.trim="reportLocal.notes"
                  clearable
                  hide-details="auto"
                  no-resize
                  label="Notizen"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ACCOUNTING } from "@/store/modules.js";
import { GET_PAYROLL_TYPES } from "@/store/action-types.js";
import DutyTypeChip from "@/components/duty-management/DutyTypeChip.vue";
export default {
  name: "report-live-general-view",
  props: {
    report: {},
    duration: {},
    // mode: {
    //   type: String,
    //   default: "new",
    //   required: true,
    //   validator: function (value) {
    //     return ["new", "edit"].indexOf(value) !== -1;
    //   },
    // },
  },
  components: { DutyTypeChip },
  data() {
    return {
      helpers: {
        eventSelectionDialog: {
          model: false,
          loading: false,
          selectedEvent: null,
          errorMessage: "",
        },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      statusTypes: [],
    };
  },
  model: {
    prop: "report",
    event: "reportChange",
  },
  computed: {
    reportLocal: {
      get: function () {
        return this.report;
      },
      set: function (value) {
        this.$emit("reportChange", value);
      },
    },
    durationLocal: {
      get: function () {
        return this.duration;
      },
      set: function (value) {
        this.$emit("durationChange", value);
      },
    },

    payrollTypes() {
      return this.$store.state.accounting.payrollTypes.map((payrollType) => {
        return {
          title: payrollType.title,
          id: payrollType.meta.id,
        };
      });
    },
    requiredInputFields() {
      const payrollTypes = this.$store.state.accounting.payrollTypes;
      const selectedPayrollTypeId = this.reportLocal.payrollType.id;

      const selectedPayrollType = payrollTypes.find(
        (payrollType) => payrollType.meta.id === selectedPayrollTypeId
      );

      if (selectedPayrollType) {
        return selectedPayrollType.customVariables;
      }
      return [];
    },
    dutyTypes() {
      return [{ ...this.reportLocal.type }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    "reportLocal.payrollType.id": function () {
      this.initializeCustomVariables();
    },
  },
  methods: {
    initialize() {
      // Get payrolltypes
      this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.initializeCustomVariables();

      // Get memberships
    },
    initializeCustomVariables() {
      const customVariables = { ...this.reportLocal.customVariables };
      this.requiredInputFields.forEach((field) => {
        if (!customVariables[field.id]) {
          customVariables[field.id] = "";
        }
      });
      this.reportLocal.customVariables = customVariables;
    },
    toggleEventSelectionDialog() {
      this.helpers.eventSelectionDialog.model =
        !this.helpers.eventSelectionDialog.model;
    },
    setNow(type) {
      const now = new Date();
      const date = now.toISOString().split("T")[0];
      const time = now
        .toTimeString()
        .split(" ")[0]
        .split(":")
        .slice(0, 2)
        .join(":");
      if (type === "start") {
        this.$set(this.durationLocal.start, "date", date);
        this.$set(this.durationLocal.start, "time", time);
      } else if (type === "end") {
        this.$set(this.durationLocal.end, "date", date);
        this.$set(this.durationLocal.end, "time", time);
      }
    },
  },
};
</script>
