<template>
  <data-chip
    :text="eventType.title"
    :iconLeft="eventType.icon"
    :color="eventType.color"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "event-type-chip",
  components: {
    DataChip,
  },
  props: {
    eventType: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
