<template>
  <div>
    <v-card-title class="subtitle-1 font-weight-medium">
      Qualifikationsübersicht
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed small v-bind="attrs" v-on="on">
            <span>
              <strong>{{ selectedSkillGroupTitle }}</strong>
            </span>
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item-group v-model="selectedSkillGroupId" mandatory>
            <v-list-item link dense value="positions">
              <v-list-item-icon>
                <v-icon dense>mdi-tag</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Funktionen</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="skillGroup in skillGroups"
              :key="skillGroup.meta.id"
              link
              dense
              :value="skillGroup.meta.id"
            >
              <v-list-item-icon>
                <v-icon dense>mdi-{{ skillGroup.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ skillGroup.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row v-if="selectedSkillGroupId === 'positions'" dense>
        <v-col cols="12" v-if="positions.length">
          <v-chip
            v-for="(position, index) in positions"
            :key="index"
            label
            x-large
            class="font-weight-medium text--darken-4 mr-4 mb-2 px-8"
            @click="togglePositionDetailsDialog(position.position.id)"
            v-bind:style="createBorderColorStyle(position.position.color)"
            ><div>
              <div class="text-h5 font-weight-medium text-center mb-n1">
                {{ position.counter }}
              </div>
              <div class="subtitle-2 text-center text--darken-4">
                {{ position.position.shortTitle
                }}<span v-if="position.isOptional">*</span>
              </div>
            </div></v-chip
          >
        </v-col>
        <v-col cols="12" v-else>
          <v-card-text
            class="grey--text text-center d-flex align-center justify-center mt-2"
          >
            Keine Funktionen durch Einsatzmittel angefordert.
          </v-card-text>
        </v-col>
      </v-row>
      <v-row v-else dense>
        <v-col
          v-for="skill in skills"
          :key="skill.meta.id"
          cols="6"
          sm="4"
          md="4"
          lg="3"
        >
          <v-card
            outlined
            class="d-flex justify-center align-center"
            @click="toggleSkillDetailsDialog(skill.meta.id)"
          >
            <v-img
              contain
              height="60px"
              width="60px"
              :src="skill.badgeUrl.size200x200"
              class="my-2 mx-2"
            ></v-img>

            <v-card
              flat
              :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'"
              class="mr-0 py-0 text-center d-flex justify-center align-center"
              min-width="60px"
              :light="!$vuetify.theme.dark"
              :dark="$vuetify.theme.dark"
            >
              <div>
                <div class="text-h5 font-weight-medium text-center mb-n1">
                  {{ skill.counter }}
                </div>
                <div class="subtitle-2 text-center text--darken-4">
                  {{ skill.shortTitle }}
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text> </v-card-text>
  </div>
</template>

<script>
export default {
  name: "attendance-overview-card-content",
  components: {},
  props: {
    dutyAttendances: {
      type: Array,
      required: true,
      default: () => [],
    },
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      selectedSkillGroupId: "positions",
    };
  },
  computed: {
    skills() {
      const skills = this.$store.state.memberManagement.skills;

      const getCount = (skillId) => {
        return this.dutyAttendances.filter((attendance) => {
          return (
            attendance.attendanceStatus === "1" &&
            attendance.user.relevantSkills.data.some(
              (skill) => skill.id === skillId
            )
          );
        }).length;
      };

      return skills
        .filter((skill) => skill.group.id === this.selectedSkillGroupId)
        .sort((a, b) => a.sortKey - b.sortKey)
        .map((skill) => {
          return {
            ...skill,
            counter: getCount(skill.meta.id),
          };
        });
    },
    positions() {
      const requiredPositions = [];
      const getCount = (positionId) => {
        return this.dutyAttendances.filter((attendance) => {
          return (
            attendance.attendanceStatus === "1" &&
            attendance.user.fillablePositions.ids.some(
              (id) => id === positionId
            )
          );
        }).length;
      };

      this.report.units.data.forEach((unit) => {
        unit.selectedCrewCombination.positions.forEach((position) => {
          if (
            !requiredPositions.some(
              (p) => p.position.id === position.position.id
            )
          ) {
            requiredPositions.push(position);
          }
        });
      });

      return requiredPositions.map((position) => {
        return {
          ...position,
          counter: getCount(position.position.id),
        };
      });
    },
    skillGroups() {
      return this.$store.state.memberManagement.skillGroups;
    },
    selectedSkillGroupTitle() {
      if (this.selectedSkillGroupId === "positions") {
        return "Funktionen";
      } else {
        const selectedSkillGroup = this.skillGroups.find(
          (group) => group.meta.id === this.selectedSkillGroupId
        );
        return selectedSkillGroup.title || "";
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    toggleSkillDetailsDialog(skillId) {
      //TODO
      console.log(skillId);
      alert("Keine Qualifikationen hinterlegt.");
    },
    togglePositionDetailsDialog(positionId) {
      console.log(positionId);
      alert("Keine Qualifikationen hinterlegt.");
    },
    createBorderColorStyle(color) {
      const style = "border-left: 12px solid " + color;
      return style;
    },
  },
};
</script>
